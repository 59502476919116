import {
  MD,
  ID,
  FD,
  PTX,
  PDC,
  PST,
  MStyles,
  CBB,
  BMD,
} from 'CASINO_CORE__UI/apps/GameLoadingApp/core__gameLoading';
import styled from 'styled-components';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

const MD_EX = styled(MD)`
  background-image: none;
`;

const BMD_EX = styled(BMD)`
  @media (min-width: ${BREAKPOINT_L}) {
    width: 90% !important;
  }
`;

export { MD_EX as MD, ID, FD, PTX, PDC, PST, MStyles, CBB, BMD_EX as BMD };
