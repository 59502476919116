import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import ScDecoder from 'Services/json/core__decoder';
import AllGameApp from './component/core__allGamesReact';
import createStore, {
  carouselSearchBar,
  navigationList,
  searchIconClick,
  backIconClick,
  storeSearchValue,
  loadInitialGames,
  gotoSelectedGame,
  navigatetoSelectedGame,
  gotoNextSelectionGames,
  gotoPrevSelectionGames,
  getFavouriteGames,
  gotoNextFavSelectionGames,
  gotoPrevFavSelectionGames,
  fetchData,
  addGametoFavourite,
  removeGameFromFavourite,
  updateGamesData,
} from './core__all-games-store';
import { setAppConfig } from '../AllGamesApp/component/helper/carouselAppConfig';
import { stringsToTranslate } from './core__all-games-translations';
import { TranslationsProvider } from 'Services/translations/core__translations';
export const Translations = new TranslationsProvider('AllGamesApp');

class AllGamesApp {
  constructor(properties) {
    Object.assign(this, properties);
    if (this.nodeSSR) {
      Translations.get = properties.Translations?.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations?.getAll?.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    if (this.appConfig?.iframeNav) {
      setAppConfig(this.appConfig);
    }
    const mapStateToProps = state => ({
      isshowCasinoSearch: state.CasinoGamesData.isshowCasinoSearch,
      navigationItems: state.CasinoGamesData.navigationItems,
      isEnableSearchTab: state.CasinoGamesData.isEnableSearchTab,
      getSearchValue: state.CasinoGamesData.getSearchValue,
      activeIcon: state.CasinoGamesData.activeIcon,
      gamesData: state.CasinoGamesData.gamesData,
      filterGamesData: state.CasinoGamesData.filterGamesData,
      favouriteGamesData: state.CasinoGamesData.favouriteGamesData,
      filterFavouriteGamesData: state.CasinoGamesData.filterFavouriteGamesData,
      theme: this.appConfig?.theme,
      imgPlaceholderUrl: this.appConfig?.imgplaceholderurl,
      appConfig: this.appConfig,
      gamesDataCopy: state.CasinoGamesData.gamesDataCopy,
      allGamesInfo: state.CasinoGamesData.allGamesInfo,
      removeGameProvider: this.appConfig?.removegameprovider?.split(','),
    });
    const mapDispatchToProps = {
      carouselSearchBar: carouselSearchBar,
      navigationList: navigationList,
      searchIconClick: searchIconClick,
      backIconClick: backIconClick,
      storeSearchValue: storeSearchValue,
      loadInitialGames: loadInitialGames,
      gotoSelectedGame: gotoSelectedGame,
      navigatetoSelectedGame: navigatetoSelectedGame,
      gotoNextSelectionGames: gotoNextSelectionGames,
      gotoPrevSelectionGames: gotoPrevSelectionGames,
      getFavouriteGames: getFavouriteGames,
      gotoNextFavSelectionGames: gotoNextFavSelectionGames,
      gotoPrevFavSelectionGames: gotoPrevFavSelectionGames,
      fetchData: fetchData,
      removeGameFromFavourite: removeGameFromFavourite,
      addGametoFavourite: addGametoFavourite,
      appConfig: this.appConfig,
      updateGamesData: updateGamesData,
    };
    AllGameApp.serverFetch = {
      createStore: createStore,
      fetchData,
      strToTranslate: stringsToTranslate,
    };

    return connect(mapStateToProps, mapDispatchToProps)(AllGameApp);
  }
}

export default AllGamesApp;
