import React from 'react';
import {
  StyledEventsLink,
  Container,
  Image,
  Text,
} from 'CASINO_UI/apps/AllGamesApp/FilterBtn';
import PropTypes from 'prop-types';
import { Translations } from '../../../core__all-games-app';
import { useDispatch } from 'react-redux';
import { toggleFilterModalWindow } from '../../../core__all-games-filter-actions';

export const FilterBtn = () => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Container data-testid="Container">
        <StyledEventsLink onClick={() => dispatch(toggleFilterModalWindow())}>
          <Image src="https://assets.fsbtech.com/react/olybet/icons/settings.svg" />
          <Text data-testid="Text">{Translations.get('casino.filter')}</Text>
        </StyledEventsLink>
      </Container>
    </React.Fragment>
  );
};

FilterBtn.propTypes = {
  handleModal: PropTypes.func,
};
