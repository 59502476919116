import styled, { css } from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import theme from 'UI/globals/theme';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';
import { BRAND, FONT, GREYS } from 'UI/globals/colours';

export const Wrapper = styled.div`
  background: #111539;
  font-family: ${theme.fontFamily};
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 70px;
`;

export const Games = styled.div`
  width: 70%;
  height: 100%;
  padding: 0 24px;
  margin-top: 24px;
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
  overflow: scroll;
  display: ${props => (props?.active ? `block` : 'none')};
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    margin-top: 0px;
  }
  div {
    max-width: none;
  }
`;

export const FilterResult = styled.div`
  font-weight: 700;
  color: white;
  padding: 10px 7px;
  margin-right: 10%;
`;

export const GamesList = styled.div`
  width: 100%;
  height: 40px;
  > div {
    max-width: none;
    margin: 0;
  }
  div section {
    justify-content: start;
  }
`;

export const Topbar = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  height: 48px;
  border-bottom: 1px solid ${GREYS.blueD};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const Title = styled.div`
  text-align: center;
  color: white;
  padding: 12px 0px;
  margin: 0;
  font-family: Nunito Sans, sans-serif;
`;

export const CloseIcon_CP = styled(CloseIcon)`
  right: 12px;
  top: 12px;
  position: absolute;
`;

export const Filters = styled.div`
  width: 30%;
  height: 100%;
  padding: 0 24px;
  margin-top: 24px;
  box-sizing: border-box;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
  }
`;

export const Accordion = styled.div`
  width: 100%;
  color: ${GREYS.white};
  margin-bottom: 5px;
  font-weight: bold;
  span {
    padding-left: 10px;
    padding-top: 0;
  }
`;

export const Btn = styled.div`
  padding: 7px 15px;
  gap: 8px;
  background: ${GREYS.blueL};
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: inline-flex;
  margin: 0 5px 4px 0;
  color: ${GREYS.wheat};
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      background-color: ${FONT.secondary};
      color: ${GREYS.white};
    }
  }
  ${({ active }) =>
    active &&
    css`
      background: ${FONT.secondary};
      color: white;
    `}
`;

export const Footer = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  @media (max-width: ${BREAKPOINT_S}) {
    display: block;
  }
`;

export const ShowBtn = styled.div`
  padding: 10px 12px;
  align-items: center;
  background-color: ${BRAND.secondary};
  border-radius: 24px;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  text-align: center;
  flex-flow: column;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
  width: 80%;
  margin: 0 auto;
  border: 2px solid transparent;
  &:hover {
    background-color: white;
    color: ${BRAND.secondary};
  }
`;

export const ClearBtn = styled(ShowBtn)`
  border-color: white;
  background-color: #111539;
  margin-top: 10px;
  &:hover {
    background-color: #111539;
    color: white;
  }
  &:before {
    content: 'X';
    padding-right: 15px;
    display: inline;
    font-size: 14px;
  }
`;

export const MStyles = {
  modal: {
    width: '100%',
    borderRadius: '4px',
    margin: 0,
    backgroundColor: '#111539',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
};

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ShowAll = styled.div`
  width: 100%;
  text-decoration: underline;
  cursor: pointer;
  color: white;
`;
