import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EventsLink from 'UI/navigation/TopLink';
import { gotoSelectedGame } from '../../../core__all-games-store';
import { Translations } from '../../../core__all-games-app';
import { UL } from 'CASINO_UI/apps/AllGamesApp/NavigationList';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

/**
 * this component contains the Navigation list
 * @param {*} navigationItems array containing the navigation list
 * @param {*} isEnableSearchTab bool to enable search tab
 * @param {*} activeIcon string to highlight selected tab
 * @param {*} gotoSelectedGame function to navigate to selected game
 * */

export class NavigationListPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}
  render() {
    const {
      navigationItems,
      isEnableSearchTab,
      activeIcon,
      isOlympic,
      productRefs,
      casinoTheme,
      inlineSearch,
      reloadPageOnTabChange,
    } = this.props;
    let newNavigationItems = navigationItems;

    if (isOlympic) {
      newNavigationItems = navigationItems.filter(item =>
        productRefs.split(',').includes(item.navLink)
      );
    }

    return (
      <React.Fragment>
        {!isEnableSearchTab && (
          <UL casinoTheme={casinoTheme} inlineSearch={inlineSearch}>
            {navigationItems &&
              newNavigationItems.map((item, ind) => {
                const translatedLabel = Translations.get(
                  'casino.category.list.' +
                    item.navIcon?.toLowerCase().replace(/ /g, '')
                );
                return (
                  <li key={ind}>
                    <EventsLink
                      casinoTheme={casinoTheme}
                      data-test={ind === 0 && 'tab-navclick'}
                      label={
                        isOlympic
                          ? translatedLabel.toUpperCase()
                          : translatedLabel
                      }
                      active={
                        activeIcon?.toLowerCase() ===
                        item.navIcon?.toLowerCase()
                      }
                      className={
                        activeIcon?.toLowerCase() ===
                          item.navIcon?.toLowerCase() && 'navActive'
                      }
                      onClick={() =>
                        reloadPageOnTabChange
                          ? (window.location.href = item?.navLink)
                          : item?.navIcon === GLOBAL_CONSTANTS.PROMOTIONS_S
                          ? (window.location = item?.navLink)
                          : this.props.gotoSelectedGame(
                              item?.navLink,
                              item?.navIcon
                            )
                      }
                      gameIcon={item.gameIcon}
                    />
                  </li>
                );
              })}
          </UL>
        )}
      </React.Fragment>
    );
  }
}

NavigationListPage.propTypes = {
  navigationItems: PropTypes.array,
  isEnableSearchTab: PropTypes.bool,
  activeIcon: PropTypes.string,
  isOlympic: PropTypes.bool,
  gotoSelectedGame: PropTypes.func,
  productRefs: PropTypes.string,
  casinoTheme: PropTypes.bool,
  inlineSearch: PropTypes.bool,
  reloadPageOnTabChange: PropTypes.bool,
};

export const mapStateToProps = state => {
  return {
    navigationItems: state.CasinoGamesData.navigationItems,
    isEnableSearchTab: state.CasinoGamesData.isEnableSearchTab,
    activeIcon: state.CasinoGamesData.activeIcon,
  };
};

export const mapDispatchToProps = {
  gotoSelectedGame: gotoSelectedGame,
};

NavigationListPage.defaultProps = {
  navigationItems: [],
  isEnableSearchTab: false,
  activeIcon: '',
};

export const NavListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationListPage);
