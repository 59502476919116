export const setFilteredIds = newFilteredIds => dispatch => {
  dispatch({ type: 'SET_FILTERED_IDS', payload: newFilteredIds });
};

export const toggleFilterFeatures = id => dispatch => {
  dispatch({ type: 'TOGGLE_FILTER_FEATURES', payload: id });
};

export const toggleFilterGameTypes = id => dispatch => {
  dispatch({ type: 'TOGGLE_FILTER_GAMETYPES', payload: id });
};

export const toggleFilterMaxWinMultipliers = id => dispatch => {
  dispatch({ type: 'TOGGLE_FILTER_MAXWINMULTIPLIERS', payload: id });
};

export const toggleFilterVolatilitys = id => dispatch => {
  dispatch({ type: 'TOGGLE_FILTER_VOLATILITYS', payload: id });
};

export const toggleFilterProviders = id => dispatch => {
  dispatch({ type: 'TOGGLE_FILTER_PROVIDERS', payload: id });
};

export const resetAllFilters = () => dispatch => {
  dispatch({ type: 'RESET_ALL_FILTERS' });
};

export const setGamesData = gamesData => dispatch => {
  dispatch({ type: 'SET_GAMES_DATA', payload: gamesData });
};

export const setOptionsFeatures = options => dispatch => {
  dispatch({ type: 'SET_OPTIONS_FEATURES', payload: options });
};

export const setOptionsGameTypes = options => dispatch => {
  dispatch({ type: 'SET_OPTIONS_GAME_TYPES', payload: options });
};

export const setOptionsMaxWinMultipliers = options => dispatch => {
  dispatch({ type: 'SET_OPTIONS_MAX_WIN_MULTIPLIERS', payload: options });
};

export const setOptionsVolatilitys = options => dispatch => {
  dispatch({ type: 'SET_OPTIONS_VOLATILITYS', payload: options });
};

export const setOptionsProviders = options => dispatch => {
  dispatch({ type: 'SET_OPTIONS_PROVIDERS', payload: options });
};

export const setFirstRender = value => dispatch => {
  dispatch({ type: 'SET_FIRST_RENDER', payload: value });
};

export const toggleFilterModalWindow = value => dispatch => {
  dispatch({ type: 'TOGGLE_FILTER_MODAL_WINDOW', payload: value });
};

export const setFilterResultDisplay = value => dispatch => {
  dispatch({ type: 'SET_FILTER_RESULT_DISPLAY', payload: value });
};
