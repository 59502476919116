export const gamesFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_FILTERED_IDS':
      return { ...state, filteredIds: action.payload };

    case 'TOGGLE_FILTER_FEATURES':
      if (state.filterFeatures.includes(action.payload)) {
        return {
          ...state,
          filterFeatures: state.filterFeatures.filter(
            item => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          filterFeatures: [...state.filterFeatures, action.payload],
        };
      }

    case 'TOGGLE_FILTER_GAMETYPES':
      if (state.filterGameTypes.includes(action.payload)) {
        return {
          ...state,
          filterGameTypes: state.filterGameTypes.filter(
            item => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          filterGameTypes: [...state.filterGameTypes, action.payload],
        };
      }

    case 'TOGGLE_FILTER_MAXWINMULTIPLIERS':
      if (state.filterMaxWinMultipliers.includes(action.payload)) {
        return {
          ...state,
          filterMaxWinMultipliers: state.filterMaxWinMultipliers.filter(
            item => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          filterMaxWinMultipliers: [
            ...state.filterMaxWinMultipliers,
            action.payload,
          ],
        };
      }

    case 'TOGGLE_FILTER_VOLATILITYS':
      if (state.filterVolatilitys.includes(action.payload)) {
        return {
          ...state,
          filterVolatilitys: state.filterVolatilitys.filter(
            item => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          filterVolatilitys: [...state.filterVolatilitys, action.payload],
        };
      }

    case 'TOGGLE_FILTER_PROVIDERS':
      if (state.filterProviders.includes(action.payload)) {
        return {
          ...state,
          filterProviders: state.filterProviders.filter(
            item => item !== action.payload
          ),
        };
      } else {
        return {
          ...state,
          filterProviders: [...state.filterProviders, action.payload],
        };
      }

    case 'RESET_ALL_FILTERS':
      return {
        ...state,
        filterFeatures: [],
        filterGameTypes: [],
        filterMaxWinMultipliers: [],
        filterVolatilitys: [],
        filterProviders: [],
      };

    case 'SET_GAMES_DATA':
      return { ...state, gamesData: action.payload };

    case 'SET_OPTIONS_FEATURES':
      return { ...state, optionsFeatures: action.payload };

    case 'SET_OPTIONS_GAME_TYPES':
      return { ...state, optionsGameTypes: action.payload };

    case 'SET_OPTIONS_MAX_WIN_MULTIPLIERS':
      return { ...state, optionsMaxWinMultipliers: action.payload };

    case 'SET_OPTIONS_VOLATILITYS':
      return { ...state, optionsVolatilitys: action.payload };

    case 'SET_OPTIONS_PROVIDERS':
      return { ...state, optionsProviders: action.payload };

    case 'SET_FIRST_RENDER':
      return { ...state, firstRender: action.payload };

    case 'SET_FILTER_RESULT_DISPLAY':
      return { ...state, filterResultDisplay: action.payload };

    case 'TOGGLE_FILTER_MODAL_WINDOW':
      return {
        ...state,
        filterModalWindow: !state.filterModalWindow,
      };

    default:
      return state;
  }
};
