export const filterInitialState = {
  CasinoFilterData: {
    filteredIds: [],
    filterFeatures: [],
    filterGameTypes: [],
    filterMaxWinMultipliers: [],
    filterVolatilitys: [],
    filterProviders: [],
    gamesData: [],
    optionsFeatures: [],
    optionsGameTypes: [],
    optionsMaxWinMultipliers: [],
    optionsVolatilitys: [],
    optionsProviders: [],
    firstRender: true,
    filterModalWindow: false,
    filterResultDisplay: false,
  },
};

export const utilsValue = {
  CLOSE: 'close',
  CLOSE_PARENT: 'close.parent',
  REDIRECT_CASINO: '/casino/',
};
