import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import EA from 'UI/globals/ExpandArrow';
import {
  Accordion,
  Title,
} from 'CASINO_UI/apps/CasinoFiltering/CasinoFiltering';

const Title_CP = styled(Title)`
  text-align: left;
  padding: 5px 0;
  cursor: pointer;
  display: flex;
  margin-bottom: 5px;
  justify-content: flex-start;
  @media (max-width: ${BREAKPOINT_S}) {
    justify-content: space-between;
  }
`;

const Container = styled.div``;

const FilterAccordion = props => {
  const [open, setOpen] = useState(props.open);
  const toggle = () => {
    setOpen(!open);
  };

  return (
    <Accordion data-testid="Accordion">
      <Title_CP onClick={toggle} data-testid="Title">
        {props.title}
        <EA expanded={open} size={2} />
      </Title_CP>
      {open && <Container data-testid="Container">{props.children}</Container>}
    </Accordion>
  );
};

FilterAccordion.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  open: PropTypes.bool,
};
export default FilterAccordion;
