import styled from 'styled-components';
import { BREAKPOINT_L, BREAKPOINT_S } from 'UI/globals/breakpoints';
import { BRAND, GREYS, SUPPLEMENTARY } from 'UI/globals/colours';

// Main div to display nav links and search bar
export const MD = styled.div`
  display: flex;
  align-items: center;
  max-width: 1288px;
  padding: 0 1em;
  position: relative;
  @media (max-width: ${BREAKPOINT_L}) {
    overflow: ${({ inlineSearch }) => (inlineSearch ? 'none' : 'auto')};
    align-items: center;
    padding-right: ${({ inlineSearch }) => (inlineSearch ? '14px' : '0px')};
  }
`;
// Div to get border
export const BB = styled.div`
  width: 100%;
  border-bottom: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.silver}` : `1px solid ${BRAND.tertiary}`};
`;
// Main Div to display search and navigation list
export const MDD = styled.div`
  max-width: ${({ showSearchWithClearButton }) =>
    !showSearchWithClearButton && `1288px`};
  margin: 0 auto;
  display: flex;
  background-color: ${({ theme, appConfig, isOlympic }) =>
    isOlympic
      ? 'transparent'
      : appConfig.purplebg
      ? `${SUPPLEMENTARY.purpleD}`
      : theme.dark
      ? `${SUPPLEMENTARY.black}`
      : `${GREYS.white}`};
  @media (max-width: ${BREAKPOINT_L}) {
    max-width: ${({ showSearchWithClearButton }) =>
      showSearchWithClearButton ? `100%` : '90%'};
    display: ${({ showSearchWithClearButton }) =>
      showSearchWithClearButton ? `flex` : 'block'};
    margin: 0 auto;
  }
`;
export const MDC = styled.div`
  display: flex;
  width: 1288px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_S}) {
    width: 100%;
  }
  @media (max-width: ${BREAKPOINT_L}) {
    width: 100%;
  }
`;
export const LS = styled.div``;
