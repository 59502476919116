import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';
import { getCookie } from '../../../../../../services/cookie/core__cookies';

import {
  GC,
  GN,
  JK,
  ModelStyles,
  HB,
  MP,
  DTN,
  DTD,
  RIBBON,
  RIBBON_TEXT,
} from 'CASINO_CORE__UI/apps/AllGamesApp/core__gamesListingPage';
import {
  GI,
  HOD,
  HOPD,
  IMAGE,
  LD,
  LTD,
  PN as PN_LOCAL,
  HPN as HPN_LOCAL,
  HD,
  DP,
  PG,
} from 'CASINO_UI/apps/AllGamesApp/GamesListingpage';
import {
  PN as PN_CORE,
  HPN as HPN_CORE,
} from 'CASINO_CORE__UI/apps/AllGamesApp/core__gamesListingPage';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import { EC } from 'CASINO_CORE__UI/apps/GameLoadingApp/core__gameLoading';
import { FD } from 'CASINO_UI/apps/GameLoadingApp/GameLoading';
import { getIcon } from 'Services/icons/core__icons';
import I from 'UI/globals/Icons';
import { Translations } from '../../../core__all-games-app';
import Constants from '../../../../../../project';
import { Modal } from 'CORE__UI/globals/Modal/core__modal';
import { detectMob } from '../../../../../core__casino-utils';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { getComponent } from 'Services/core__imports';
const PN = getComponent(PN_LOCAL, PN_CORE);
const HPN = getComponent(HPN_LOCAL, HPN_CORE);

export const GameListingItem = ({
  game,
  navigatetoSelectedGame,
  currencyCode,
  playNowBtn,
  Olympic,
  addGametoFavourite,
  removeGameFromFavourite,
  ApplyFavButtonToEachTile,
  iframeNav,
  showmore,
  customer,
  checkGeo,
  hideDemoPlay,
  hideThumbGameName,
  gamesSelected,
  productRef,
  urlAmendments,
  demoPlayActiveLink,
}) => {
  const { favouriteGamesData } = useSelector(state => ({
    favouriteGamesData: state.CasinoGamesData.favouriteGamesData,
  }));
  const [sizerValue, setSizerValue] = useState(Number);
  const [handleModal, sethandleModal] = useState(false);
  const [isMobile, setMobileState] = useState('');
  const [sessionAvaiable, setSession] = useState(false);

  const arr = favouriteGamesData?.gameType?.map(e => {
    return e.id;
  });

  const isFavorite = arr?.includes(game?.id);
  const onDemoPlayClick = e => {
    e.stopPropagation();
    navigatetoSelectedGame(
      game?.name,
      game?.id,
      iframeNav,
      'fun',
      productRef,
      urlAmendments,
      demoPlayActiveLink
    );
  };

  const openLoginModalClick = e => {
    e.stopPropagation();
    //if model is open then its should be close first
    sethandleModal(false);
    PubSub.emit(PubsubEvents.callLoginModalFromCasino, {
      modal: true,
      page: 'fromCasino',
    });
  };
  // open game when uer login and request come from mobile web
  const playNowMob = () => {
    navigatetoSelectedGame(
      game?.name,
      game?.id,
      iframeNav,
      undefined,
      productRef,
      urlAmendments,
      demoPlayActiveLink
    );
  };

  const SpinImg = `${Constants.staticPath.basePath}${
    Constants.staticPath.bucketReact
  }${Constants.staticPath.folderIcon}${'spinner.gif'}`;

  const FireModal = () => {
    sizerValue > 900
      ? navigatetoSelectedGame(
          game?.name,
          game?.id,
          iframeNav,
          undefined,
          productRef,
          urlAmendments,
          demoPlayActiveLink
        )
      : sethandleModal(true);
  };

  useEffect(() => {
    const b = window.innerWidth;
    setSizerValue(b);
    function resizeListener() {
      const c = window?.innerWidth;
      setSizerValue(c);
    }
    const deviceState = detectMob();
    setMobileState(deviceState);
    window.addEventListener('resize', resizeListener);
    const breakpointPubSub = PubSub.listen(
      PubsubEvents.BreakPoint,
      breakPoint => {
        if (breakPoint !== 'BREAKPOINT_XL' && breakPoint !== 'BREAKPOINT_L') {
          setMobileState(true);
        } else {
          setMobileState(false);
        }
      }
    );
    const sessionToken = getCookie('AppSession');
    if (sessionToken) {
      setSession(true);
    }

    return () => {
      window.removeEventListener('resize', resizeListener);
      breakpointPubSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session?.accessToken) {
        setSession(true);
      }
    });
    return () => {
      sessionCreated?.unsubscribe();
    };
  }, []);

  const checkGeoComply = () => {
    const geo = getCookie('geo');
    if (!geo) {
      PubSub.emit(PubsubEvents.GEOCOMPLY_EVENT, { showGeocomplyCheck: true });
    } else {
      navigatetoSelectedGame(
        game?.name,
        game?.id,
        iframeNav,
        undefined,
        productRef,
        urlAmendments,
        demoPlayActiveLink
      );
    }
  };

  const complyCheck = () => {
    if (!customer?.kycApproved) {
      PubSub.emit(PubsubEvents.OPEN_ID_COMPLY, customer);
    } else {
      checkGeoComply();
    }
  };

  return (
    <GC
      gameHighlight={game.isHighlight}
      isOlympic={Olympic}
      className="lazy-loaded-div lazy"
      style={{ visibility: 'hidden' }}
    >
      <GI
        gameHighlight={game.isHighlight}
        isOlympic={Olympic}
        onClick={() => {
          if (checkGeo && customer?.accessToken) {
            complyCheck();
          } else {
            Olympic
              ? FireModal()
              : navigatetoSelectedGame(
                  game?.name,
                  game?.id,
                  iframeNav,
                  undefined,
                  productRef,
                  urlAmendments,
                  demoPlayActiveLink
                );
          }
        }}
        gamesSelected={gamesSelected}
      >
        {Olympic ? (
          <HPN>
            {sessionAvaiable ? (
              <DP
                showJNModal={true}
                label={Translations.get('casino.playnow')}
              />
            ) : (
              game?.gameOptions[0] && (
                <DP
                  onClick={e => openLoginModalClick(e)}
                  label={Translations.get(
                    'text.' + game?.gameOptions[0]?.toLowerCase()
                  )}
                />
              )
            )}
            {hideDemoPlay !== true && game?.gameOptions[1] && (
              <PG
                onClick={e => onDemoPlayClick(e)}
                label={Translations.get(
                  'casino.' +
                    game?.gameOptions[1]?.toLowerCase().replaceAll(' ', '')
                )}
              />
            )}
          </HPN>
        ) : playNowBtn ? (
          <HPN>
            <PN>
              {Translations.get('casino.category.play.now').toUpperCase()}
            </PN>
          </HPN>
        ) : (
          <HOPD>
            <HOD></HOD>
          </HOPD>
        )}
        <IMAGE
          key={game?.imageUrl}
          className="lazy-loaded-image lazy"
          data-src={game?.imageUrl}
          alt={!showmore && 'image'}
          bckgimg={SpinImg}
          loading="lazy"
          onError={event => {
            event.target.onerror = '';
            event.target.src = game?.baseImageUrl
              ? game.baseImageUrl
              : game?.placeHolderImageUrl;
            return true;
          }}
        />
        {game?.jackpotAmount && (
          <JK>
            {CurrencyFormat(currencyCode)}
            {game.jackpotAmount}
          </JK>
        )}
        {game?.displayTags?.length > 0 && game?.tagColours?.length === 0 ? (
          <LTD>
            {game?.displayTags?.map((tag, tInd) => {
              return (
                <LD tag={tag} key={tInd} indx={tInd}>
                  {tag}
                </LD>
              );
            })}
          </LTD>
        ) : (
          game?.displayTags?.length > 0 &&
          game?.tagColours?.length > 0 && (
            <LTD>
              {game?.displayTags?.map((tag, tInd) => {
                return Olympic && tag.toLowerCase() === 'new' ? (
                  <RIBBON tag={tag} key={tInd} indx={tInd}>
                    <RIBBON_TEXT>{tag}</RIBBON_TEXT>
                  </RIBBON>
                ) : (
                  <LD
                    tag={tag}
                    key={tInd}
                    indx={tInd}
                    colourCode={game.tagColours[tInd]}
                  >
                    {tag}
                  </LD>
                );
              })}
            </LTD>
          )
        )}
        {game?.featuredTag && (
          <DTN>
            <DTD>{game.featuredTag}</DTD>
          </DTN>
        )}
        {ApplyFavButtonToEachTile && (
          <HD onClick={e => e.stopPropagation()}>
            {isFavorite ? (
              <EC>
                <FD
                  data-test={'btn-removeGameclick'}
                  onClick={() => {
                    removeGameFromFavourite(game.id);
                  }}
                >
                  <I
                    iconName={getIcon('STARACTIVE')}
                    size={isMobile ? 20 : 28}
                  />
                </FD>
              </EC>
            ) : (
              <EC>
                <FD
                  data-test={'btn-addGameclick'}
                  onClick={() => {
                    addGametoFavourite(game.id);
                  }}
                >
                  <I iconName={getIcon('STAR')} size={isMobile ? 20 : 28} />
                </FD>
              </EC>
            )}
          </HD>
        )}
        {game?.isLiveGame && !!hideThumbGameName && <GN>{game?.name}</GN>}
      </GI>
      {Olympic && (
        <Modal
          data-test="close-modal"
          open={handleModal}
          center
          styles={ModelStyles}
          onClose={() => sethandleModal(false)}
          closeIcon={<CloseIcon />}
        >
          <HB>{game.name}</HB>
          {(sessionAvaiable && (
            <React.Fragment>
              <MP
                label={Translations.get('casino.playnow')}
                onClick={() => playNowMob()}
              ></MP>
              {!hideDemoPlay && (
                <MP
                  label={Translations.get('casino.demoplay')}
                  onClick={e => onDemoPlayClick(e)}
                />
              )}
            </React.Fragment>
          )) || (
            <React.Fragment>
              <MP
                label={Translations.get('text.login')}
                onClick={e => openLoginModalClick(e)}
              ></MP>
              {!hideDemoPlay && (
                <MP
                  label={Translations.get('casino.demoplay')}
                  onClick={e => onDemoPlayClick(e)}
                />
              )}
            </React.Fragment>
          )}
        </Modal>
      )}
    </GC>
  );
};

GameListingItem.propTypes = {
  currencyCode: PropTypes.any,
  playNowBtn: PropTypes.bool,
  Olympic: PropTypes.bool,
  addGametoFavourite: PropTypes.func,
  ApplyFavButtonToEachTile: PropTypes.bool,
  showDemoPlay: PropTypes.bool,
  game: PropTypes.shape({
    baseImageUrl: PropTypes.any,
    displayTags: PropTypes.array,
    id: PropTypes.any,
    imageUrl: PropTypes.any,
    isHighlight: PropTypes.any,
    isLiveGame: PropTypes.any,
    jackpotAmount: PropTypes.any,
    name: PropTypes.any,
    placeHolderImageUrl: PropTypes.any,
    featuredTag: PropTypes.any,
    tagColours: PropTypes.array,
    gameOptions: PropTypes.array,
  }),
  navigatetoSelectedGame: PropTypes.func,
  removeGameFromFavourite: PropTypes.func,
  favouriteGamesData: { gameType: PropTypes.any },
  iframeNav: PropTypes.bool,
  showmore: PropTypes.bool,
  customer: PropTypes.object,
  checkGeo: PropTypes.bool,
  hideThumbGameName: PropTypes.bool,
  hideDemoPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  gamesSelected: PropTypes.bool,
  productRef: PropTypes.string,
  urlAmendments: PropTypes.bool,
  demoPlayActiveLink: PropTypes.bool,
};
