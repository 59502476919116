import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from './core__casino-filtering-accordion';
import { SearchPage } from '../SearchBar';
import { GameListingPage } from '../GamesListingPage';
import {
  buildImageUrl,
  loadFilterOptions,
  getGameOptions,
} from '../../../core__all-games-store';
import PropTypes from 'prop-types';
import { Translations } from '../../../core__all-games-app';
import {
  Wrapper,
  Container,
  Games,
  Topbar,
  CloseIcon,
  Title,
  Filters,
  Btn,
  Footer,
  ShowBtn,
  ClearBtn,
  TopContainer,
  GamesList,
  FilterResult,
  RightContainer,
  ShowAll,
} from 'CASINO_UI/apps/CasinoFiltering/CasinoFiltering';

import {
  setFilteredIds,
  toggleFilterFeatures,
  toggleFilterGameTypes,
  toggleFilterMaxWinMultipliers,
  toggleFilterVolatilitys,
  toggleFilterProviders,
  resetAllFilters,
  setGamesData,
  setFirstRender,
  toggleFilterModalWindow,
  setFilterResultDisplay,
} from '../../../core__all-games-filter-actions';

import {
  storeInitialLoadGames,
  initialLoadGamesCopy,
} from '../../../core__all-games-store';

const CasinoFilters = ({ appConfig, resetGamesList, allGamesInfo }) => {
  const {
    filteredIds,
    filterFeatures,
    filterGameTypes,
    filterMaxWinMultipliers,
    filterVolatilitys,
    filterProviders,
    gamesData,
    optionsFeatures,
    optionsGameTypes,
    optionsMaxWinMultipliers,
    optionsVolatilitys,
    optionsProviders,
    firstRender,
    filterModalWindow,
  } = useSelector(state => ({
    filteredIds: state.CasinoFilterData.filteredIds,
    filterFeatures: state.CasinoFilterData.filterFeatures,
    filterGameTypes: state.CasinoFilterData.filterGameTypes,
    filterMaxWinMultipliers: state.CasinoFilterData.filterMaxWinMultipliers,
    filterVolatilitys: state.CasinoFilterData.filterVolatilitys,
    filterProviders: state.CasinoFilterData.filterProviders,
    gamesData: state.CasinoFilterData.gamesData,
    optionsFeatures: state.CasinoFilterData.optionsFeatures,
    optionsGameTypes: state.CasinoFilterData.optionsGameTypes,
    optionsMaxWinMultipliers: state.CasinoFilterData.optionsMaxWinMultipliers,
    optionsVolatilitys: state.CasinoFilterData.optionsVolatilitys,
    optionsProviders: state.CasinoFilterData.optionsProviders,
    firstRender: state.CasinoFilterData.firstRender,
    filterModalWindow: state.CasinoFilterData.filterModalWindow,
  }));
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const [showAllProviders, setShowAllProviders] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadFilterOptions(allGamesInfo));
  }, [allGamesInfo, dispatch]);

  useEffect(() => {
    dispatch(setFilteredIds([]));
    const newFilteredGames = [];
    const allGames = allGamesInfo;
    allGames.map(game => {
      // Filter by gametype
      filterGameTypes.includes(game.product.ref) &&
        !newFilteredGames.includes(game.id) &&
        newFilteredGames.push(game.id);
      // Filter by metadata
      const metadata_arr = [
        'maxWinMultiplier',
        'features',
        'volatility',
        'developer',
      ];
      game.metadata &&
        game.metadata.map(
          metadata =>
            metadata_arr.includes(metadata.name) &&
            (filterFeatures.includes(metadata.value) ||
              filterMaxWinMultipliers.includes(metadata.value) ||
              filterProviders.includes(metadata.value) ||
              filterVolatilitys.includes(metadata.value)) &&
            !newFilteredGames.includes(game.id) &&
            newFilteredGames.push(game.id)
        );
    });
    dispatch(setFilteredIds(newFilteredGames));
    const filteredGames = [];
    allGames.map(game => {
      if (newFilteredGames.includes(game.id)) {
        const getImageUrl = buildImageUrl(game);
        game.imageUrl = getImageUrl?.imageUrl;
        game.placeHolderImageUrl = getImageUrl?.placeHolderImageUrl;
        game.gameOptions = getGameOptions(gamesData);
        filteredGames.push(game);
      }
    });
    gamesData.gameType = filteredGames;
    gamesData.gamesCount = filteredGames.length;
    gamesData.title = 'results';
    gamesData.icon = 'https://assets.fsbtech.com/react/olybet/icons/check.svg';

    if (!firstRender) {
      dispatch(storeInitialLoadGames([gamesData]));
      dispatch(initialLoadGamesCopy([gamesData]));
    }
    dispatch(setFirstRender(false)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterFeatures,
    filterGameTypes,
    filterMaxWinMultipliers,
    filterVolatilitys,
    filterProviders,
  ]);

  const onShowGames = () => {
    dispatch(toggleFilterModalWindow());
    dispatch(setFilterResultDisplay(true));
  };

  const clearFilters = () => {
    // Reset all Filters
    dispatch(resetAllFilters());
    // Set games to initial state
    dispatch(setGamesData([]));
    // Load initial games
    resetGamesList();
    dispatch(setFilterResultDisplay(false));
  };

  const closePopup = () => {
    clearFilters();
    dispatch(toggleFilterModalWindow());
    dispatch(setFirstRender(true));
  };
  return (
    <Wrapper>
      <React.Fragment>
        <Topbar data-testid="Topbar">
          <Title>{Translations.get('casino.filter.title')}</Title>
          <CloseIcon onClick={closePopup} />
        </Topbar>
        <Container filterItems={filteredIds?.length > 0}>
          <Filters>
            {optionsFeatures.length > 0 && (
              <Accordion
                title={Translations.get('casino.filter.features')}
                open={true}
              >
                {optionsFeatures.map(
                  (option, key) =>
                    (key < 6 || showAllFeatures) && (
                      <Btn
                        key={key}
                        active={filterFeatures.includes(option)}
                        onClick={() => dispatch(toggleFilterFeatures(option))}
                      >
                        {option}
                      </Btn>
                    )
                )}
              </Accordion>
            )}
            {optionsFeatures.length > 0 && optionsFeatures.length > 6 && (
              <ShowAll onClick={() => setShowAllFeatures(!showAllFeatures)}>
                {Translations.get('casino.buttontext.showall')} (
                {optionsFeatures.length})
              </ShowAll>
            )}
            {optionsGameTypes.length > 0 && (
              <Accordion
                title={Translations.get('casino.filter.gametype')}
                open={true}
              >
                {optionsGameTypes.map((option, key) => (
                  <Btn
                    key={key}
                    active={filterGameTypes.includes(option)}
                    onClick={() => dispatch(toggleFilterGameTypes(option))}
                  >
                    {option}
                  </Btn>
                ))}
              </Accordion>
            )}
            {optionsMaxWinMultipliers.length > 0 && (
              <Accordion
                title={Translations.get('casino.filter.maxwinmultiplier')}
                open={true}
              >
                {optionsMaxWinMultipliers.map((option, key) => (
                  <Btn
                    key={key}
                    active={filterMaxWinMultipliers.includes(option)}
                    onClick={() =>
                      dispatch(toggleFilterMaxWinMultipliers(option))
                    }
                  >
                    {option}
                  </Btn>
                ))}
              </Accordion>
            )}
            {optionsVolatilitys.length > 0 && (
              <Accordion
                title={Translations.get('casino.filter.volatility')}
                open={true}
              >
                {optionsVolatilitys.map((option, key) => (
                  <Btn
                    key={key}
                    active={filterVolatilitys.includes(option)}
                    onClick={() => dispatch(toggleFilterVolatilitys(option))}
                  >
                    {option}
                  </Btn>
                ))}
              </Accordion>
            )}
            {optionsProviders.length > 0 && (
              <Accordion
                title={Translations.get('casino.filter.provider')}
                open={true}
              >
                {optionsProviders.map(
                  (option, key) =>
                    (key < 6 || showAllProviders) && (
                      <Btn
                        key={key}
                        active={filterProviders.includes(option)}
                        onClick={() => dispatch(toggleFilterProviders(option))}
                      >
                        {option}
                      </Btn>
                    )
                )}
              </Accordion>
            )}
            {optionsProviders.length > 0 && optionsProviders.length > 6 && (
              <ShowAll onClick={() => setShowAllProviders(!showAllProviders)}>
                {Translations.get('casino.buttontext.showall')}(
                {optionsProviders.length})
              </ShowAll>
            )}
          </Filters>
          <Games active={filterModalWindow}>
            <TopContainer>
              <SearchPage isOlympic={true} />
              <RightContainer>
                {filteredIds.length > 0 && (
                  <FilterResult>
                    {filteredIds.length + ' '}
                    {filteredIds.length > 1
                      ? Translations.get('casino.filter.gamesfound')
                      : Translations.get('casino.filter.gamefound')}
                  </FilterResult>
                )}
              </RightContainer>
            </TopContainer>
            <GamesList>
              <GameListingPage appConfig={appConfig} />
            </GamesList>
          </Games>
        </Container>
        {filteredIds.length > 0 && (
          <Footer>
            <ShowBtn onClick={onShowGames}>
              {Translations.get('casino.filter.showgames')} (
              {filteredIds.length})
            </ShowBtn>
            <ClearBtn onClick={clearFilters}>
              {Translations.get('casino.filter.clear')}
            </ClearBtn>
          </Footer>
        )}
      </React.Fragment>
    </Wrapper>
  );
};

CasinoFilters.propTypes = {
  appConfig: PropTypes.object,
  resetGamesList: PropTypes.func,
  allGamesInfo: PropTypes.array,
};

export default CasinoFilters;
