import { getRequest } from 'Services/http/core__axios';

export const detectMob = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some(toMatchItem => {
    return navigator?.userAgent?.match(toMatchItem);
  });
};

export function calculateIframeHeightAndSpace(containerRef, navbarHeight = 0) {
  const iframeRect = containerRef.current.getBoundingClientRect();
  const windowHeight = window.innerHeight;

  // Calculate missing height at the bottom
  const missingHeightValue = Math.max(
    0,
    iframeRect.bottom - (windowHeight - navbarHeight)
  );

  // Calculate blank space below the iframe
  const blankSpaceBelow = Math.max(
    0,
    windowHeight - navbarHeight - iframeRect.bottom
  );

  // Convert pixel values to vh
  const missingHeightVH = Math.floor((missingHeightValue / windowHeight) * 100);
  const blankSpaceBelowVH = Math.floor((blankSpaceBelow / windowHeight) * 100);

  // Calculate current height of the iframe in vh
  const currentIframeHeightVH = Math.floor(
    (iframeRect.height / windowHeight) * 100
  );

  return {
    missingHeightVH, // Return cropped space if any
    blankSpaceBelowVH, // Return blank space below iframe if any.
    currentIframeHeightVH, // Return current height incase on height prop in custodian
  };
}

export function isSafariMobile() {
  const isMobile = detectMob();
  const isSafari =
    /iPhone|iPad|iPod/i.test(navigator.userAgent) &&
    /Safari/i.test(navigator.userAgent);
  const isLandscape = window.innerWidth > window.innerHeight;
  const isToolbarOpen = isSafariToolbarOpen();

  return isMobile && isSafari && isLandscape && !isToolbarOpen;
}

export function isSafariToolbarOpen() {
  const innerHeight = window.innerHeight;
  const outerHeight = window.outerHeight;
  return innerHeight < outerHeight;
}

export const setPageParamsToConfig = appConfig => {
  try {
    const bodyTag =
      document.getElementsByTagName('body') &&
      document.getElementsByTagName('body')[0];
    if (bodyTag.attributes) {
      for (let i = 0; i < bodyTag.attributes.length; i++) {
        const attr = bodyTag.attributes[i];
        const key = attr.name.replace('data-', '');
        appConfig[key] = attr.value;
      }
    }
  } catch (e) {
    //no handling required
  }
  return appConfig;
};

export function lazyImageLoad() {
  /** First we get all the non-loaded image elements **/
  var lazyImages = [].slice.call(
    document.querySelectorAll('.lazy-loaded-image.lazy')
  );
  /** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element **/
  const lazyImageObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;
        lazyImage.src = lazyImage.dataset.src;
        lazyImage.classList.remove('lazy');
        lazyImageObserver.unobserve(lazyImage);
      }
    });
  });

  /** Now observe all the non-loaded images using the observer we have setup above **/
  lazyImages.forEach(function (lazyImage) {
    lazyImageObserver.observe(lazyImage);
  });
}

export function lazyDivLoad() {
  var lazyDivs = [].slice.call(
    document.querySelectorAll('.lazy-loaded-div.lazy')
  );
  const lazyDivObserver = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        const lazyDiv = entry.target;
        lazyDiv.style.visibility = 'visible';
        lazyDiv.classList.remove('lazy');
        lazyDivObserver.unobserve(lazyDiv);
      }
    });
  });

  lazyDivs.forEach(function (lazyDiv) {
    lazyDivObserver.observe(lazyDiv);
  });
}

export const getRecentGames = async (
  token,
  setRecentGamesData,
  removeGameProvider
) => {
  if (token) {
    const fetchUrl = `/fsb-api-rest/customerRecentGames.fsb?access_token=${token}&limit=10`;
    return getRequest(fetchUrl).then(res => {
      if (res) {
        removeGameProvider?.map(p => {
          const filteredGames = res?.gameType?.filter(
            s => s.gameProvider.ref !== p
          );
          res.gameType = filteredGames;
        });
        return setRecentGamesData(res);
      }
    });
  }
};
