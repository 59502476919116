import { MD, BB } from 'CASINO_CORE__UI/apps/AllGamesApp/core__allGames';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

const BB_EX = styled(BB)`
  border-bottom: 1px solid ${GREYS.silver};
`;

const MD_OL = styled(MD)`
  justify-content: space-between;
`;

export { MD_OL as MD, BB_EX as BB };
