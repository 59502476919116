import React, { useEffect, useState } from 'react';
import {
  CD,
  NP,
  TC,
  TCM,
  THD,
} from 'CASINO_UI/apps/AllGamesApp/GamesListingpage';
import {
  GI,
  HOD,
  HOPD,
  IMAGE,
} from 'CASINO_UI/apps/AllGamesApp/GamesListingpage';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { getRecentGames } from '../../../../../core__casino-utils';
import { buildImageUrl } from '../../../core__all-games-store';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { carouselSettings } from './carouselConfig';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { ScrollCarousel } from 'Services/carousel/core__scroll-carousel';
import { Translations } from '../../../core__all-games-app';
import PropTypes from 'prop-types';

const settings = carouselSettings();

export const RecentGames = ({ removeGameProvider }) => {
  const [recentGamesData, setRecentGamesData] = useState({});
  const [token, setToken] = useState(false);
  useEffect(() => {
    const sessionCreate = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setToken(session.accessToken);
      }
    });

    //get customer data when user signs in and the page is reloaded in order to get to the deposits
    const sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setToken(session.accessToken);
      }
    });

    // Clean useEffect out after render
    return () => {
      sessionCreate.unsubscribe();
      sessionUpdated.unsubscribe();
    };
  }, [token]);

  useEffect(() => {
    token && getRecentGames(token, setRecentGamesData, removeGameProvider);
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  const [moveNext, setMoveNext] = useState(null);
  const [movePrev, setMovePrev] = useState(null);

  function getOrBuildImageUrl(game) {
    if (Array.isArray(game.metadata) && game.metadata.length > 0) {
      for (const metadata of game.metadata) {
        if (metadata.name === 'imageThumbnail') {
          return metadata.value;
        }
      }
    }
    return buildImageUrl(game)?.imageUrl;
  }

  return (
    <React.Fragment>
      {token && recentGamesData?.gameType?.length && (
        <>
          <THD>
            <TCM>
              <TC>{Translations.get('text.recently.played')}</TC>
              <CD>{recentGamesData?.pageInfo?.total}</CD>
            </TCM>
            <TCM>
              <React.Fragment>
                <NP
                  onClick={() => {
                    setMovePrev(Math.random());
                  }}
                >
                  {<I iconName={getIcon('CLOSE')} size={24} />}
                </NP>
                <NP
                  showmore={true}
                  onClick={() => {
                    setMoveNext(Math.random());
                  }}
                >
                  {<I iconName={getIcon('OPEN')} size={24} />}
                </NP>
              </React.Fragment>
            </TCM>
          </THD>
          <ScrollCarousel
            movePercent={100}
            {...settings}
            moveNext={moveNext}
            movePrev={movePrev}
          >
            {recentGamesData?.gameType?.map(game => {
              return (
                <GI
                  key={buildImageUrl(game).imageUrl}
                  onClick={() => {
                    const name = game.name.replace(' ', '_');
                    window.location.href = `/casino/game/${name}/?id=${game.id}`;
                  }}
                >
                  <HOPD>
                    <HOD></HOD>
                  </HOPD>
                  <IMAGE
                    className="lazy-loaded-image lazy"
                    data-src={getOrBuildImageUrl(game)}
                    src={getOrBuildImageUrl(game)}
                    alt="image"
                    loading="lazy"
                  ></IMAGE>
                </GI>
              );
            })}
          </ScrollCarousel>
        </>
      )}
    </React.Fragment>
  );
};

RecentGames.propTypes = {
  removeGameProvider: PropTypes.array,
};
