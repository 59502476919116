import styled from 'styled-components';
import { BRAND, FONT, GREYS, FUNCTIONAL } from 'UI/globals/colours';
import { BREAKPOINT_L, BREAKPOINT_S } from 'UI/globals/breakpoints';
import ModalButton from 'UI/buttons/ModalButton';

// Main div to display games
export const MD = styled.div`
  max-width: 1288px;
  margin: 0 auto;
  @media (max-width: ${BREAKPOINT_L}) {
    max-width: 100%;
    padding: 0 8px;
  }
`;

// games grid
export const GG = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, 214px);
  width: 100%;
  justify-content: center;

  @media (max-width: ${BREAKPOINT_S}) {
    grid-template-columns: repeat(auto-fill, 110px);
  }
`;

export const GC = styled.div`
  ${props =>
    props?.gameHighlight &&
    `
      grid-column: 1 / span 2;
      `};
  padding: ${props => (props?.isOlympic ? '8px' : '0px')};
`;

export const BD = styled.div`
  display: ${props => props?.gameHighlight && `contents`};
`;

// Game item div
export const GI = styled.div`
  ${props =>
    props?.gameHighlight
      ? 'width: calc((100% / 3) - 8px)'
      : 'width: calc((100% / 6) - 8px)'};
  height: ${props => (props?.gameHighlight ? '422px' : '208px')};
  width: ${props => (props?.gameHighlight ? '422px' : '208px')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin: 4px 4px 4px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: ${({ theme }) =>
    theme.dark ? `1px solid ${FONT.dark}` : `1px solid ${BRAND.tertiary}`};

  img {
    width: 100%;
    height: 100%;
    border-radius: ${props => (props?.isOlympic ? '16px' : '4px')};
  }

  @media (max-width: ${BREAKPOINT_S}) {
    width: ${props => (props?.gameHighlight ? '222px' : '107px')};
    height: ${props => (props?.gameHighlight ? '222px' : '107px')};
    margin: 4px 4px 4px 0;
  }

  &:hover > div {
    display: flex;
  }
  overflow: hidden;
`;
// Label display div
export const LD = styled.span`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  font-size: 12px;
  font-weight: 800;
  color: rgb(28, 28, 28);
  letter-spacing: 0.25px;
  text-transform: uppercase;
  background-color: ${({ theme }) =>
    theme.dark ? BRAND.primaryD : FONT.secondary};
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  &:last-child {
    margin-right: 0px;
  }
`;
// Label tp div
export const LTD = styled.span`
  position: absolute;
  top: 8px;
  left: 4px;
`;

// Play now button
export const PN = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  padding: 12px 8px 8px 8px;
  border-radius: 4px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
`;

export const HPN = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  align-items: center;
  color: ${GREYS.white};
  justify-content: center;
`;

// Title header div
export const THD = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;
// Title and count diaplay main div
export const TCM = styled.div`
  display: flex;
  align-items: center;
`;
// Title diaplay div
export const TC = styled.div`
  margin-right: 8px;
  color: ${FONT.primary};
  font-weight: 700;
`;
// Title image
export const TI = styled.img`
  width: 80px;
  margin: -22px -12px -20px -20px;
`;
// Count diaplay div
export const CD = styled.div`
  color: ${GREYS.black};
  background-color: ${({ theme }) =>
    theme.dark ? FONT.secondary : GREYS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 28px;
  max-width: 48px;
  height: 28px;
  font-weight: bold;
  padding: 0 2px;
  font-size: 11px;
`;
// All games text diaplay div
export const GT = styled.div`
  margin-left: 16px;
  cursor: pointer;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Next and prev icon diaplay div
export const NP = styled.span`
  margin-left: 16px;
  cursor: pointer;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Empty div
export const ED = styled.div`
  clear: both;
`;
// Image tag
export const IMAGE = styled.img`
  background-color: ${GREYS.white};
`;
// hover tag
export const HOD = styled.div`
  display: block;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 36px solid ${FONT.primary};
  margin: 0 auto;
  position: relative;
  z-index: 1;
  left: 6px;

  &:before {
    content: "";
    position: absolute;
    top: -45px;
    left: -69px;
    bottom: -45px;
    right: -21px;
    border-radius: 50%;
    border: 6px solid ${FONT.primary};
    z-index: 2;
  }

  &:after {
    content: "";
    opacity: 0;
    transition: opacity .6s;
  }
}
`;
// hover parent div
export const HOPD = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  bottom: 0;
  display: none;
  text-align: center;
  background-color: ${GREYS.black};
  opacity: 0.9;
  transition: all 0.2s linear;
`;
// Jackpot amount display
export const JK = styled.span`
  background-color: rgba(0, 0, 0, 0.65);
  padding: 4px;
  position: absolute;
  width: 100%;
  font-weight: 700;
  top: 0;
  display: inline-block;
  left: 0;
  text-align: center;
  color: ${GREYS.white};
`;
// Game name display
export const GN = styled.span`
  background-color: rgba(27, 27, 27, 0.5);
  padding: 7px 0;
  position: absolute;
  width: 100%;
  font-size: 12px;
  left: 0;
  text-align: center;
  color: ${GREYS.white};
  text-shadow: 2px 2px 1px rgb(34 34 34 / 40%);
  z-index: 2;
  bottom: 0;
  opacity: 1;
`;
export const HD = styled.span`
  position: absolute;
  bottom: -15px;
  right: 6px;
`;
export const DP = styled(ModalButton)`
  position: absolute;
  top: 32%;
  @media (max-width: ${BREAKPOINT_S}) {
    top: 32%;
    height: 24px;
  }
`;
export const PG = styled(ModalButton)`
  position: absolute;
  background-color: ${FUNCTIONAL.documentU};
  top: 56%;
  border: 1px solid ${GREYS.white};
  @media (max-width: ${BREAKPOINT_S}) {
    top: 56%;
    height: 24px;
  }
`;
export const ModelStyles = {
  modal: {
    width: '350px',
    margin: 20,
    padding: '10px',
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingBottom: '10px',
    borderRadius: '16px',
  },
};
export const HB = styled.h3`
  width: 100%;
  text-align: center;
  color: white;
  margin-bottom: 25px;
  word-break: break-all;
  margin-top: 15px;
`;
export const MP = styled(ModalButton)`
   margin-top: 20px;
   margin-bottom: 10px;
   background: none;
   border: 1px solid ${GREYS.doveD};
   &:hover{
     background-color: ${BRAND.primary};
     border: none;
   } 
  }
`;

export const DTN = styled.div`
  background-color: ${BRAND.secondary};
  height: 22px;
  right: -22px;
  position: absolute;
  top: 6px;
  width: 80px;
  -webkit-transform: rotate(45deg);
  display: flex;
  padding: 4px;
  color: ${GREYS.white};
`;

export const DTD = styled.span`
  left: 25px;
  top: 4px;
  position: absolute;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
`;

export const RIBBON = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  left: 105px;
  top: -18px;
  @media (max-width: ${BREAKPOINT_S}) {
    left: 18px;
    top: -22px;
  }
`;

export const RIBBON_TEXT = styled.span`
  position: absolute;
  display: block;
  width: 187px;
  padding: 5px 0;
  background-color: ${BRAND.secondaryL};
  color: ${GREYS.white};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  left: -26px;
  top: 19px;
  transform: rotate(45deg);
`;
