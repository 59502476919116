import {
  Wrapper,
  Container,
  Games,
  Topbar,
  Title,
  Filters,
  Accordion,
  Btn,
  Footer,
  ShowBtn,
  ClearBtn,
  MStyles,
  CloseIcon_CP,
  TopContainer,
  GamesList,
  FilterResult,
  RightContainer,
  ShowAll,
} from 'CASINO_CORE__UI/apps/CasinoFiltering/core__CasinoFiltering';
import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const Wrapper_EX = styled(Wrapper)`
  height: 100%;
  @media (max-width: ${BREAKPOINT_S}) {
    display: flex;
    flex-direction: column;
  }
`;

const Filters_EX = styled(Filters)`
  overflow-y: auto;
  padding-bottom: 20px;
`;

const Container_EX = styled(Container)`
  @media (max-width: ${BREAKPOINT_S}) {
    overflow-y: ${props => props?.filterItems && 'scroll'};
    position: ${props => props?.filterItems && 'relative'};
  }
`;

const Footer_EX = styled(Footer)`
  position: relative;
`;

export {
  Wrapper_EX as Wrapper,
  Container_EX as Container,
  Games,
  Topbar,
  Title,
  Filters_EX as Filters,
  Accordion,
  Btn,
  CloseIcon_CP as CloseIcon,
  Footer_EX as Footer,
  ShowBtn,
  ClearBtn,
  MStyles,
  TopContainer,
  GamesList,
  FilterResult,
  RightContainer,
  ShowAll,
};
