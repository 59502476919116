import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { Translations } from '../../../core__all-games-app';
import { GameListingItem } from './core__game-listing-item';
import {
  CD,
  ED,
  GT,
  NP,
  TC,
  TCM,
  THD,
  TI,
  BD as BD_LOCAL,
} from 'CASINO_UI/apps/AllGamesApp/GamesListingpage';
import { BD as BD_CORE } from 'CASINO_CORE__UI/apps/AllGamesApp/core__gamesListingPage';
import { carouselSettings } from './carouselConfig';

import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
import { getCookie } from 'Services/cookie/core__cookies';
import { GLOBAL_CONSTANTS } from 'Services/core__services';

const settings = carouselSettings();
const BD = BD_LOCAL ? BD_LOCAL : BD_CORE;
export const GameListingSection = ({
  data,
  isEnableSearchTab,
  gamesData,
  Olympic,
  index,
  gotoSelectedGame,
  currencyCode,
  Container,
  navigatetoSelectedGame,
  appConfig,
  playNowBtn,
  addGametoFavourite,
  removeGameFromFavourite,
  ApplyFavButtonToEachTile,
  carouselShift,
  gamesSelected,
}) => {
  const [moveNext, setMoveNext] = useState(null);
  const [movePrev, setMovePrev] = useState(null);
  const [counter, setCounter] = useState(100);
  const [customer, setCustomer] = useState({});
  const intervalRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const localekey = getCookie('localeKey');

  useEffect(() => {
    if (carouselShift) {
      return () => stopCounter(); // when App is unmounted we should stop counter
    }
  }, [carouselShift]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) setCustomer(session);
    });

    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) setCustomer(session);
    });

    return () => {
      sessionUpdated.unsubscribe();
      sessionCreated.unsubscribe();
    };
  }, [customer]);

  const screenSizeListener = () => {
    setIsMobile(window.innerWidth < parseInt(BREAKPOINT_S));
  };

  useEffect(() => {
    screenSizeListener();
  });

  useEffect(() => {
    window.addEventListener('resize', screenSizeListener);
    return () => window.removeEventListener('resize', screenSizeListener);
  });

  const showArrowIcons = data => {
    const highlightGames = data?.gameType?.filter(game =>
      data?.gametohighlight?.includes(game.name)
    );
    const iconsLength =
      data?.gameType?.length -
      highlightGames?.length +
      highlightGames?.length * 4;
    return !isMobile && iconsLength > 10;
  };

  const startCounter = prev => {
    if (carouselShift) {
      if (intervalRef.current) return;
      intervalRef.current = setInterval(() => {
        prev
          ? setMovePrev(Math.random())
          : setMoveNext(prevCounter => prevCounter + 1);
        setCounter(counter + 1);
      }, 400);
    }
  };

  const stopCounter = () => {
    if (carouselShift) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  };
  //set unique games list & sort by Highlight asc
  const games = (data?.gameType || []).reduce((acc, game) => {
    if (acc.some(gameItem => gameItem.id === game.id)) return acc;
    return [...acc, game];
  }, []);

  if (appConfig?.sortbyhighlight) {
    // ordinal order 1,2,3,...∞,0
    games.sort((a, b) => {
      if (a.isHighlight) return -1;
      if (a.order === 0) return 1;
      if (b.order === 0) return -1;
      return a.order - b.order;
    });
  }

  return (
    <React.Fragment>
      {games?.length > 0 && (
        <THD>
          <TCM>
            {isEnableSearchTab && gamesData?.length === 1 ? (
              <TC>{Translations.get('casino.search.results')}</TC>
            ) : (
              data.title && (
                <React.Fragment>
                  {data.icon && <TI src={data.icon} />}
                  <TC>
                    {appConfig.showmore
                      ? localekey === GLOBAL_CONSTANTS.EN
                        ? data.title
                        : Translations.get(
                            `casino.category.list.${data.title
                              ?.toLowerCase()
                              .replace(/\s+/g, '')}`
                          )
                      : Olympic
                      ? Translations.get(
                          'casino.category.list.' +
                            data.title?.toLowerCase().replaceAll(' ', '')
                        )
                      : Translations.get(
                          'casino.category.list.' +
                            data.title?.toLowerCase().replace(' ', '')
                        ).indexOf('{') === -1
                      ? Translations.get(
                          'casino.category.list.' +
                            data.title?.toLowerCase().replace(' ', '')
                        ).toUpperCase()
                      : localekey === GLOBAL_CONSTANTS.EN
                      ? data.title
                      : Translations.get(
                          `casino.category.list.${data.title
                            ?.toLowerCase()
                            .replace(/\s+/g, '')}`
                        )}
                  </TC>
                </React.Fragment>
              )
            )}
            {!Olympic && (
              <CD>
                {data?.gamesCount ? data.gamesCount : data?.gameType?.length}
              </CD>
            )}
          </TCM>
          {(data?.showViewButton || appConfig?.showviewbutton) && (
            <TCM>
              {!appConfig?.showviewbutton && (
                <>
                  {data?.gameref && appConfig?.showmore ? (
                    <GT
                      data-test={index === 1 && 'tab-gameclick'}
                      onClick={() => gotoSelectedGame(data.gameref)}
                    >
                      {Translations.get('casino.text.more.games')}
                    </GT>
                  ) : (
                    data?.gameref &&
                    data?.buttontext && (
                      <GT
                        data-test={index === 1 && 'tab-gameclick'}
                        onClick={() => gotoSelectedGame(data.gameref)}
                      >
                        {Olympic
                          ? Translations.get(
                              'casino.buttontext.' +
                                data?.buttontext?.toLowerCase().replace(' ', '')
                            )
                          : Translations.get(
                              'casino.buttontext.' +
                                data?.buttontext?.toLowerCase().replace(' ', '')
                            ).indexOf('{') === -1
                          ? Translations.get(
                              'casino.buttontext.' +
                                data?.buttontext?.toLowerCase().replace(' ', '')
                            )
                          : data?.buttontext}
                      </GT>
                    )
                  )}
                </>
              )}
              {data?.gamesdisplaycountininitialload && showArrowIcons(data) && (
                <React.Fragment>
                  <NP
                    data-test={index === 1 && 'label-prevSelection'}
                    onClick={() => {
                      setMovePrev(Math.random());
                    }}
                    onMouseDown={() => startCounter('prev')}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                  >
                    {
                      <I
                        iconName={getIcon('CLOSE')}
                        size={
                          appConfig?.chevronsize
                            ? 16
                            : appConfig?.showmore
                            ? 16
                            : 28
                        }
                      />
                    }
                  </NP>
                  <NP
                    data-test={index === 1 && 'label-nextSelection'}
                    showmore={appConfig?.showmore}
                    onClick={() => {
                      setMoveNext(Math.random());
                    }}
                    onMouseDown={() => startCounter()}
                    onMouseUp={stopCounter}
                    onMouseLeave={stopCounter}
                  >
                    {
                      <I
                        iconName={getIcon('OPEN')}
                        size={
                          appConfig?.chevronsize
                            ? 16
                            : appConfig?.showmore
                            ? 16
                            : 28
                        }
                      />
                    }
                  </NP>
                </React.Fragment>
              )}
            </TCM>
          )}
        </THD>
      )}
      {Array.isArray(games) && games.length > 0 && (
        <Container
          movePercent={100}
          {...settings}
          moveNext={moveNext}
          movePrev={movePrev}
        >
          {games.reduce((acc, game, ind) => {
            if (!game) {
              return acc;
            }
            let extraGame = null;
            if (!game.isHighlight && games[ind + 1] && !appConfig?.showinrow) {
              extraGame = JSON.parse(JSON.stringify(games[ind + 1]));
              games[ind + 1] = null;
            }
            acc.push(
              <BD key={ind} gameHighlight={game?.isHighlight}>
                <GameListingItem
                  playNowBtn={playNowBtn}
                  game={game}
                  customer={customer}
                  navigatetoSelectedGame={navigatetoSelectedGame}
                  currencyCode={currencyCode}
                  Olympic={Olympic}
                  addGametoFavourite={addGametoFavourite}
                  removeGameFromFavourite={removeGameFromFavourite}
                  ApplyFavButtonToEachTile={ApplyFavButtonToEachTile}
                  iframeNav={appConfig?.iframenav}
                  showmore={appConfig?.showmore}
                  hideDemoPlay={appConfig?.hidedemoplay}
                  checkGeo={appConfig?.checkgeo}
                  hideThumbGameName={appConfig?.hideThumbGameName}
                  gamesSelected={gamesSelected}
                  productRef={data?.title}
                  urlAmendments={appConfig?.urlamendments}
                  demoPlayActiveLink={appConfig?.demoplayactivelink}
                />
                {extraGame && (
                  <GameListingItem
                    playNowBtn={playNowBtn}
                    game={extraGame}
                    customer={customer}
                    navigatetoSelectedGame={navigatetoSelectedGame}
                    currencyCode={currencyCode}
                    Olympic={Olympic}
                    addGametoFavourite={addGametoFavourite}
                    removeGameFromFavourite={removeGameFromFavourite}
                    ApplyFavButtonToEachTile={ApplyFavButtonToEachTile}
                    iframeNav={appConfig?.iframenav}
                    showmore={appConfig?.showmore}
                    hideDemoPlay={appConfig?.hidedemoplay}
                    checkGeo={appConfig?.checkgeo}
                    hideThumbGameName={appConfig?.hideThumbGameName}
                    gamesSelected={gamesSelected}
                    productRef={data?.title}
                    urlAmendments={appConfig?.urlamendments}
                    demoPlayActiveLink={appConfig?.demoplayactivelink}
                  />
                )}
              </BD>
            );
            return acc;
          }, [])}
        </Container>
      )}
      <ED></ED>
    </React.Fragment>
  );
};

GameListingSection.propTypes = {
  Container: PropTypes.any,
  Olympic: PropTypes.any,
  currencyCode: PropTypes.any,
  data: PropTypes.shape({
    buttontext: PropTypes.string,
    gameType: PropTypes.array,
    gameref: PropTypes.any,
    gamesCount: PropTypes.any,
    gamesdisplaycountininitialload: PropTypes.any,
    showViewButton: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.string,
    gametohighlight: PropTypes.object,
  }),
  gamesData: PropTypes.array,
  gotoSelectedGame: PropTypes.func,
  index: PropTypes.number,
  isEnableSearchTab: PropTypes.any,
  navigatetoSelectedGame: PropTypes.any,
  appConfig: PropTypes.object,
  playNowBtn: PropTypes.bool,
  addGametoFavourite: PropTypes.func,
  removeGameFromFavourite: PropTypes.func,
  ApplyFavButtonToEachTile: PropTypes.bool,
  carouselShift: PropTypes.bool,
  gamesSelected: PropTypes.bool,
};
