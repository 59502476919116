import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  searchIconClick,
  backIconClick,
  storeSearchValue,
} from '../../../core__all-games-store';
import {
  SE,
  BL,
  Input,
  CL,
  SD,
  CLE as CLE_LOCAL,
} from 'CASINO_UI/apps/AllGamesApp/SearchBar';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import { Translations } from '../../../core__all-games-app';
import { CLE as CLE_CORE } from 'CASINO_CORE__UI/apps/AllGamesApp/core__searchBar';
import { getComponent } from 'Services/core__imports';
const CLE = getComponent(CLE_LOCAL, CLE_CORE);

export class SearchBarPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchabletext: '',
    };
  }

  componentDidUpdate() {
    const getTextBoxId = document.getElementById('textBox');
    if (getTextBoxId !== null && this.props.isEnableSearchTab) {
      getTextBoxId.focus();
    }
    if (getTextBoxId !== null && this.props.getSearchValue === '') {
      // when a user click on cancel button
      getTextBoxId.value = '';
    }
  }

  render() {
    const {
      isshowCasinoSearch,
      isEnableSearchTab,
      getSearchValue,
      filterGamesData,
      favouriteGamesData,
      gamesDataCopy,
      isOlympic,
      searchIcon,
      showSearchWithClearButton,
    } = this.props;

    return (
      <React.Fragment>
        {isshowCasinoSearch && !isEnableSearchTab ? (
          <SE
            data-test={'tab-searchclick'}
            onClick={() => this.props.searchIconClick()}
          >
            {isOlympic && <p>{Translations.get('text.search.for')}</p>}

            <I
              iconName={getIcon('SEARCH')}
              size={showSearchWithClearButton ? 12 : 28}
              marginR={searchIcon && 12}
            />
          </SE>
        ) : (
          <React.Fragment>
            {isOlympic ? (
              <SD>
                <Input
                  id="textBox"
                  data-test={'tab-inputchange'}
                  type="text"
                  value={getSearchValue}
                  placeholder={Translations.get('text.search.for')}
                  onChange={e => {
                    this.props.storeSearchValue(
                      e?.target?.value,
                      filterGamesData,
                      favouriteGamesData
                    );
                    this.state.searchabletext;
                  }}
                />
                <CL
                  data-test={'btn-clearData'}
                  onClick={() => {
                    this.props.storeSearchValue(
                      '',
                      filterGamesData,
                      favouriteGamesData
                    );
                    this.props.backIconClick(gamesDataCopy);
                  }}
                >
                  <I
                    iconName={getIcon('CLOSE_ICON')}
                    size={10}
                    padding={2}
                    marginR={0}
                  />
                </CL>
              </SD>
            ) : (
              <React.Fragment>
                {showSearchWithClearButton ? (
                  <>
                    <Input
                      id="textBox"
                      data-test={'tab-inputchange'}
                      type="text"
                      placeholder={Translations.get('casino.search.games')}
                      onKeyUp={e => {
                        this.props.storeSearchValue(
                          e?.target?.value,
                          filterGamesData,
                          favouriteGamesData
                        );
                      }}
                    />
                    <CLE
                      data-test={'btn-clearData'}
                      onClick={() =>
                        getSearchValue
                          ? this.props.storeSearchValue(
                              '',
                              filterGamesData,
                              favouriteGamesData
                            )
                          : this.props.backIconClick(gamesDataCopy)
                      }
                    >
                      <p>{Translations.get('text.search.clear')}</p>
                    </CLE>
                    <CL
                      data-test={'tab-backclick'}
                      onClick={() => this.props.backIconClick(gamesDataCopy)}
                    >
                      &#10005;
                    </CL>
                  </>
                ) : (
                  <>
                    <BL
                      data-test={'tab-backclick'}
                      onClick={() => this.props.backIconClick(gamesDataCopy)}
                    >
                      <I iconName={getIcon('BACK')} size={24} marginR={0} />
                    </BL>

                    <Input
                      id="textBox"
                      data-test={'tab-inputchange'}
                      type="text"
                      placeholder={Translations.get('casino.search.games')}
                      onKeyUp={e => {
                        this.props.storeSearchValue(
                          e?.target?.value,
                          filterGamesData,
                          favouriteGamesData
                        );
                      }}
                    />
                    <CL
                      data-test={'btn-clearData'}
                      onClick={() =>
                        getSearchValue
                          ? this.props.storeSearchValue(
                              '',
                              filterGamesData,
                              favouriteGamesData
                            )
                          : this.props.backIconClick(gamesDataCopy)
                      }
                    >
                      &#10005;
                    </CL>
                  </>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

SearchBarPage.propTypes = {
  isshowCasinoSearch: PropTypes.bool,
  searchIconClick: PropTypes.func,
  storeSearchValue: PropTypes.func,
  getSearchValue: PropTypes.string,
  isEnableSearchTab: PropTypes.bool,
  backIconClick: PropTypes.func,
  filterGamesData: PropTypes.array,
  favouriteGamesData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  gamesDataCopy: PropTypes.array,
  isOlympic: PropTypes.bool,
  hideCrossIcon: PropTypes.bool,
  searchIcon: PropTypes.bool,
  showSearchWithClearButton: PropTypes.bool,
};

export const mapStateToProps = state => {
  return {
    isshowCasinoSearch: state.CasinoGamesData.isshowCasinoSearch,
    isEnableSearchTab: state.CasinoGamesData.isEnableSearchTab,
    getSearchValue: state.CasinoGamesData.getSearchValue,
    filterGamesData: state.CasinoGamesData.filterGamesData,
    favouriteGamesData: state.CasinoGamesData.favouriteGamesData,
    gamesDataCopy: state.CasinoGamesData.gamesDataCopy,
  };
};

export const mapDispatchToProps = {
  searchIconClick: searchIconClick,
  storeSearchValue: storeSearchValue,
  backIconClick: backIconClick,
};

SearchBarPage.defaultProps = {
  isshowCasinoSearch: false,
  isEnableSearchTab: false,
  setSearchValue: '',
  filterGamesData: [],
  favouriteGamesData: {},
  gamesDataCopy: [],
};

export const SearchPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarPage);
