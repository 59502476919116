import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  gotoNextSelectionGames,
  gotoPrevSelectionGames,
  gotoSelectedGame,
  navigatetoSelectedGame,
  removeGameFromFavourite,
  addGametoFavourite,
} from '../../../core__all-games-store';
import { GG as GG_Core } from 'CASINO_CORE__UI/apps/AllGamesApp/core__gamesListingPage';
import {
  MD,
  GG as GG_Local,
} from 'CASINO_UI/apps/AllGamesApp/GamesListingpage';

import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import Constants from '../../../../../../project';

import { ScrollCarousel } from 'Services/carousel/core__scroll-carousel';
import { GameListingSection } from './core__game-listing-section';
import { RecentGames } from './core__recent-games';
import { getComponent } from 'Services/core__imports';

const GG = getComponent(GG_Local, GG_Core);

/**
 * this component contains games
 * @param {*} gamesData array to get all games
 * @param {*} gotoSelectedGame fucntion call to navigate to games
 * @param {*} navigatetoSelectedGame fucntion call to navigate to game page
 * @param {*} activeIcon string to highlight selected tab
 * @param {*} gotoNextSelectionGames funcion call to go to next games
 * @param {*} gotoPrevSelectionGames funcion call to go to prev games
 * @param {*} filterGamesData array to get filtered games data
 * */

export class GamesListingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { appConfig, gamesData, isEnableSearchTab } = this.props;
    const Olympic = appConfig.olympic;
    const ApplyFavButtonToEachTile = appConfig.applybuttontoeachtile;
    const UserAuthData = FSBCustomerSession.getSession();
    const currencyCode = UserAuthData
      ? UserAuthData.currencyCode
      : Constants.currency;
    const gameContainer = appConfig?.showviewbutton ? ScrollCarousel : GG;

    return (
      <MD>
        {appConfig?.showrecentgames && (
          <RecentGames removeGameProvider={this.props?.removeGameProvider} />
        )}
        {gamesData?.map((data, index) => {
          return (
            <GameListingSection
              playNowBtn={appConfig?.playnowbtn}
              data={data}
              key={index}
              isEnableSearchTab={isEnableSearchTab}
              Container={
                gamesData && gamesData.length > 1
                  ? ScrollCarousel
                  : gameContainer
              }
              gamesData={gamesData}
              Olympic={Olympic}
              index={index}
              gotoSelectedGame={this.props.gotoSelectedGame}
              currencyCode={currencyCode}
              navigatetoSelectedGame={this.props.navigatetoSelectedGame}
              appConfig={appConfig}
              addGametoFavourite={this.props.addGametoFavourite}
              removeGameFromFavourite={this.props.removeGameFromFavourite}
              ApplyFavButtonToEachTile={ApplyFavButtonToEachTile}
              carouselShift={appConfig?.iframenav}
              gamesSelected={gamesData && gamesData.length === 1}
            />
          );
        })}
      </MD>
    );
  }
}

GamesListingPage.propTypes = {
  olympic: PropTypes.object,
  appConfig: PropTypes.object,
  gamesData: PropTypes.array,
  gotoSelectedGame: PropTypes.func,
  navigatetoSelectedGame: PropTypes.func,
  activeIcon: PropTypes.string,
  gotoNextSelectionGames: PropTypes.func,
  gotoPrevSelectionGames: PropTypes.func,
  filterGamesData: PropTypes.array,
  isEnableSearchTab: PropTypes.bool,
  addGametoFavourite: PropTypes.func,
  removeGameFromFavourite: PropTypes.func,
  removeGameProvider: PropTypes.array,
};

export const mapStateToProps = state => {
  return {
    gamesData: state.CasinoGamesData.gamesData,
    filterGamesData: state.CasinoGamesData.filterGamesData,
    activeIcon: state.CasinoGamesData.activeIcon,
    isEnableSearchTab: state.CasinoGamesData.isEnableSearchTab,
  };
};

export const mapDispatchToProps = {
  gotoSelectedGame: gotoSelectedGame,
  navigatetoSelectedGame: navigatetoSelectedGame,
  gotoNextSelectionGames: gotoNextSelectionGames,
  gotoPrevSelectionGames: gotoPrevSelectionGames,
  addGametoFavourite: addGametoFavourite,
  removeGameFromFavourite: removeGameFromFavourite,
};

GamesListingPage.defaultProps = {
  gamesData: [],
  filterGamesData: [],
  activeIcon: '',
  isEnableSearchTab: false,
};

export const GameListingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(GamesListingPage);
