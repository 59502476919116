import { StyledEventsLink } from 'CASINO_CORE__UI/apps/AllGamesApp/core__filterBtn';
import styled from 'styled-components';
import { GREYS } from 'UI/globals/colours';

const Container = styled.div`
  margin: 10px 0 0 10px;
`;

const StyledEventsLink_CP = styled(StyledEventsLink)`
  margin: 0px;
  background: ${GREYS.blueG};
  display: flex;
`;

const Image = styled.img`
  padding-right: 10px;
`;

const Text = styled.span`
  color: ${GREYS.wheat};
`;

export { Container, StyledEventsLink_CP as StyledEventsLink, Image, Text };
