import React from 'react';
import AllGames from './components/AllGames';
import { hideCategoryMenuList } from 'Services/globalstyles/core__globalstyles';
import PropTypes from 'prop-types';
import { FSBTheme } from 'Services/core__fsb-theme';
import { lazyImageLoad, lazyDivLoad } from '../../../core__casino-utils';
import { utilsValue } from '../core__all-games-filter-initialstate';
import { getCookie } from 'Services/cookie/core__cookies';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

class AllGameApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: props?.theme,
      render: false,
    };
  }

  checkMessage = event => {
    if (event && event.data && event.data.wpgaction) {
      if (
        event.data.wpgaction === utilsValue.CLOSE ||
        event.data.wpgaction === utilsValue.CLOSE_PARENT
      ) {
        if (typeof window !== 'undefined' && window.parent) {
          //close the game screen, move to game home then to casino
          window.parent.postMessage({ wpgaction: 'close.parent' }, '*');
          window.location.href = utilsValue.REDIRECT_CASINO;
        }
      }
    }
  };
  componentDidMount() {
    this.setState({ render: true });

    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    try {
      if (
        !this.props.appConfig?.skiplobbynavigation &&
        window.parent.top.location.href.indexOf('?id=') !== -1
      ) {
        window.parent.postMessage('BackToCasino', '*');
      }
    } catch (e) {}

    hideCategoryMenuList();

    if (typeof window !== 'undefined') {
      window.addEventListener('message', event => {
        this.checkMessage(event);
      });
    }
    if (this.props?.appConfig && this.props.appConfig?.showlogin) {
      if (!getCookie('custId')) {
        PubSub.emit(PubsubEvents.openLoginModal);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', event => {
      this.checkMessage(event);
    });
  }

  componentDidUpdate() {
    setTimeout(() => {
      lazyImageLoad();
    }, 100);

    setTimeout(() => {
      lazyDivLoad();
    }, 100);
  }

  render() {
    return (
      <FSBTheme theme={this.state.theme}>
        {this.state.render && <AllGames {...this.props}></AllGames>}
      </FSBTheme>
    );
  }
}
AllGameApp.propTypes = {
  theme: PropTypes.string,
  updateGamesData: PropTypes.func,
  appConfig: PropTypes.object,
};
export default AllGameApp;
