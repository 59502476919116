import {
  MD,
  GI,
  LD,
  LTD,
  THD,
  TCM,
  TC,
  CD,
  GT,
  NP,
  ED,
  IMAGE,
  HOD,
  HOPD,
  HD,
  HPN,
  PN,
  DP,
  PG,
  GC,
  BD,
  TI,
} from 'CASINO_CORE__UI/apps/AllGamesApp/core__gamesListingPage';
import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';
const GC_OL = styled(GC)`
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  padding: 12px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding: 8px;
  }
`;
const GT_OL = styled(GT)`
  color: ${GREYS.white};
  font-size: 18px;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 14px;
  }
`;

const NP_OL = styled(NP)`
  color: ${GREYS.white};
`;

const LD_OL = styled(LD)`
  background-color: ${props =>
    props?.colourCode ? props.colourCode : GREYS.blueBG};
  color: ${GREYS.white};
  font-size: 10px;
  text-transform: capitalize;
`;

const GI_OL = styled(GI)`
  border: none;
  margin: 0px;
`;

const TC_OL = styled(TC)`
  font-size: 32px;
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 24px;
  }
`;

const HOPD_OL = styled(HOPD)`
  display: none;
`;
const BD_OL = styled(BD)`
  margin-bottom: 10px;
`;
const DP_OL = styled(DP)`
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;

const PG_OL = styled(PG)`
  background-color: ${BRAND.demoBtn};
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;
export {
  MD,
  GI_OL as GI,
  LD_OL as LD,
  LTD,
  THD,
  TCM,
  TC_OL as TC,
  CD,
  GT_OL as GT,
  NP_OL as NP,
  ED,
  IMAGE,
  HOD,
  HOPD_OL as HOPD,
  HD,
  HPN,
  PN,
  DP_OL as DP,
  PG_OL as PG,
  GC_OL as GC,
  BD_OL as BD,
  TI,
};
