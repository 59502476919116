export const stringsToTranslate = [
  'casino.search.results',
  'casino.search.games',
  'casino.category.list.toplists',
  'casino.category.list.slots',
  'casino.category.list.newgames',
  'casino.category.list.tablegames',
  'casino.category.list.blackjack',
  'casino.category.list.roulette',
  'casino.category.list.poker',
  'casino.category.list.livecasino',
  'casino.category.list.allgames',
  'casino.category.list.popularslots',
  'casino.category.list.popular',
  'casino.category.list.dropsandwins',
  'casino.buttontext.showall',
  'casino.text.more.games',
  'casino.category.list.premium',
  'casino.category.list.jackpots',
  'casino.category.list.jackpot',
  'casino.category.list.joker',
  'casino.category.list.theme',
  'casino.category.list.scratchcards',
  'casino.category.list.myfavorite',
  'casino.category.list.featured',
  'casino.category.list.results',
  'casino.category.list.hot',
  'text.search.for',
  'text.new',
  'text.hot',
  'text.popular',
  'casino.category.list.home',
  'casino.category.list.promotions',
  'casino.playnow',
  'casino.demoplay',
  'text.recently.played',
  'casino.category.list.Instant',
  'casino.category.list.popular',
  'casino.category.list.solidicongames',
  'casino.category.list.new',
  'casino.category.list.live',
  'casino.category.list.bestslots',
  'casino.category.list.dropsandwins',
  'casino.category.list.megawaysgames',
  'casino.category.list.all-games',
  'casino.category.list.best-slots',
  'casino.category.list.solid-icon',
  'favourites',
  'myfavorite',
  'casino.filter',
  'casino.filter.title',
  'casino.filter.features',
  'casino.filter.gametype',
  'casino.filter.maxwinmultiplier',
  'casino.filter.volatility',
  'casino.filter.provider',
  'casino.filter.popularfirst',
  'casino.filter.popularlast',
  'casino.filter.clear',
  'casino.filter.showgames',
  'casino.filter.gamefound',
  'casino.filter.gamesfound',
  'text.login',
  'text.search.clear',
  'casino.category.list.tech4betgames',
  'casino.category.list.yggdrasilgames',
  'casino.category.list.egt',
  'casino.category.list.egaming',
  'casino.category.list.easit',
  'casino.category.list.kajot',
  'casino.category.list.betinsight',
  'casino.category.list.adell',
  'casino.category.list.playtech',
  'casino.category.play.now',
  'casino.category.list.playnovagames',
  'casino.category.list.skywindgames',
  'casino.category.list.habanerogames',
  'casino.category.list.agsgames',
  'casino.category.list.redtiger',
  'casino.category.list.slingo',
  'casino.category.list.vegasexclusive',
  'casino.category.list.new',
  'casino.category.list.live',
  'casino.category.list.bestslots',
  'casino.category.list.megaways',
  'casino.category.list.instant',
  'casino.category.list.playsongames',
  'casino.category.list.gamesglobal',
  'casino.category.list.pragmaticgames',
  'casino.category.list.ctinteractive',
  'casino.category.list.tomhorn',
  'casino.category.list.dennepromopondelok',
  'casino.category.list.dailypromomonday',
  'casino.category.list.dailypromotuesday',
  'casino.category.list.dailypromowednesday',
  'casino.category.list.dailypromothursday',
  'casino.category.list.dailypromofriday',
  'casino.category.list.dailypromosaturday',
  'casino.category.list.dailypromosunday',
  'casino.category.list.synot',
  'casino.category.list.kalambagames',
  'casino.category.list.apollogames',
  'casino.category.list.apollo',
  'casino.category.list.gameartgames',
  'casino.category.list.endorphinagames',
  'casino.category.list.endorphina',
  'casino.category.list.playngogames',
  'casino.category.list.greentubegames',
  'casino.category.list.buyinbonus',
  'casino.category.list.fruit',
  'casino.category.list.easitgames',
  'casino.category.list.kajotgames',
  'casino.category.list.spinomenalgames',
  'casino.category.list.bfgames',
  'casino.category.list.evoplaygames',
  'casino.category.list.evoplay',
  'casino.category.list.oryx',
  'casino.category.list.7mojos',
  'casino.category.list.spinomenal',
  'casino.category.list.greentube',
  'casino.category.list.e-gaminggames',
  'casino.category.list.adellgames',
  'casino.category.list.yggdrasil',
  'casino.category.list.stakelogicgames',
  'casino.category.list.stakelogic',
  'casino.category.list.amusnet',
  'casino.category.list.casino',
  'casino.category.list.gameshows',
  'casino.category.list.atomicslotlab',
  'casino.category.list.gamomat',
  'casino.category.list.amusnetgames',
  'casino.category.list.synotgames',
  'casino.category.list.betinsightgames',
  'casino.category.list.e-gaming',
  'casino.category.list.movembertýždeň',
  'casino.category.list.dennépromoštvrtok',
  'casino.category.list.pragmaticlive',
  'casino.category.list.books',
  'casino.category.list.fazigames',
  'casino.category.list.fruitgames',
  'casino.category.list.pragmaticfeaturedgames',
  'text.verify.modal',
  'casino.category.list.pragmatic',
  'casino.category.list.crash',
];
