import styled from 'styled-components';
import { FONT, SUPPLEMENTARY } from 'UI/globals/colours';

// Div to display category list
export const CL = styled.div`
  font-size: 16px;
  font-weight: 100;
  letter-spacing: normal;
  margin-top: 8px;
  margin-bottom: 0;
`;
// ul to display nav items
export const UL = styled.ul`
  display: flex;
  flex-direction: row;
  li a {
    &:hover {
      font-weight: normal;
      color: ${({ casinoTheme }) =>
        casinoTheme ? SUPPLEMENTARY.casino : FONT.primary};
    }
    &.navActive {
      font-weight: normal;
    }
    div {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
    }
  }
`;
