import { BREAKPOINT_L, BREAKPOINT_S } from 'CORE__UI/globals/core__breakpoints';
import styled from 'styled-components';
import { BRAND, GREYS } from 'UI/globals/colours';

// Div to display search icon
export const SE = styled.div`
  display: inline-block;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  cursor: pointer;
`;
// Div to display back button
export const BL = styled.div`
  display: flex;
  line-height: 1;
  margin-right: 16px;
  font-size: 16px;
  padding: 12px 0;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  cursor: pointer;
  border-right: ${({ theme }) =>
    theme.dark ? `1px solid ${GREYS.white}` : `none`};
`;
// Input to search games
export const Input = styled.input`
  width: 100%;
  border: 0px none;
  padding-left: 20px;
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.black : BRAND.tertiaryL};
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
// Div to dipslay Search close icon
export const CL = styled.div`
  display: flex;
  line-height: 1;
  margin-left: 8px;
  font-size: 12px;
  padding: 8px 0;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  cursor: pointer;
  padding-right: 8px;
`;
// Text container
export const TEXT = styled.p``;
// Div to clear search input text
export const CLE = styled.div`
  position: absolute;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  right: 74px;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_S}) {
    top: 18px;
    right: 66px;
  }
  @media (max-width: ${BREAKPOINT_L}) {
    top: 18px;
    right: 66px;
  }
`;
