import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavListPage } from '../NavigationList';
import { SearchPage } from '../SearchBar';
import { FilterBtn } from '../FilterBtn';
import { CasinoFilters } from '../CasinoFiltering';
import { GameListingPage } from '../GamesListingPage';
import {
  MDD as MDD_CORE,
  MDC as MDC_CORE,
} from 'CASINO_CORE__UI/apps/AllGamesApp/core__allGames';
import {
  MDD as MDD_LOCAL,
  BB,
  MD,
  MDC as MDC_LOCAL,
} from 'CASINO_UI/apps/AllGamesApp/AllGames';
import { FavouriteGame } from '../FavouriteGames';
import { Modal } from 'CORE__UI/globals/Modal/core__modal';
import { MStyles } from 'CASINO_UI/apps/CasinoFiltering/CasinoFiltering';
import { useSelector, useDispatch } from 'react-redux';

import { toggleFilterModalWindow } from '../../../core__all-games-filter-actions';

const MDD = MDD_LOCAL || MDD_CORE;
const MDC = MDC_LOCAL || MDC_CORE;

/**
 * this component contains the smaller components
 * @param {*} carouselSearchBar fucntion call in initial load
 * @param {*} navigationList fucntion call in initial load
 * @param {*} loadInitialGames fucntion call in initial load
 * @param {*} gotoSelectedGame fucntion call in initial load
 * @param {*} getFavouriteGames fucntion call in initial load
 * */

const AllGamesPage = ({
  appConfig,
  carouselSearchBar,
  navigationList,
  gotoSelectedGame,
  getFavouriteGames,
  gamesData,
  fetchData,
  imgPlaceholderUrl,
  removeGameFromFavourite,
  allGamesInfo,
  removeGameProvider,
}) => {
  const { filterModalWindow, filterResultDisplay } = useSelector(state => ({
    filterModalWindow: state.CasinoFilterData.filterModalWindow,
    filterResultDisplay: state.CasinoFilterData.filterResultDisplay,
  }));
  const dispatch = useDispatch();

  const {
    CasinoGamesData: { isEnableSearchTab },
  } = useSelector(rfstate => rfstate);
  const resetGamesList = () => {
    fetchData(null, null, null);
  };

  useEffect(() => {
    carouselSearchBar();
    navigationList();
    gotoSelectedGame();
    getFavouriteGames();
    if (gamesData?.length === 0) {
      resetGamesList();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <MDD
        appConfig={appConfig}
        isOlympic={appConfig.olympic}
        setMargin={isEnableSearchTab}
        showSearchWithClearButton={appConfig?.showsearchwithclearbutton}
        inlineSearch={appConfig?.inlinesearch}
      >
        {appConfig?.showsearchwithclearbutton ? (
          <MDC>
            <MD
              isSearchIcon={true}
              isEnableSearchTab={isEnableSearchTab}
              inlineSearch={appConfig?.inlinesearch}
            >
              {appConfig?.hidesearchbar !== true && (
                <SearchPage
                  isOlympic={appConfig.olympic}
                  hideCrossIcon={appConfig?.hidecrossicon}
                  searchIcon={appConfig?.playnowbtn}
                  showSearchWithClearButton={
                    appConfig?.showsearchwithclearbutton
                  }
                />
              )}
              {appConfig?.hidecasinofilter !== true && <FilterBtn />}
            </MD>
            {appConfig?.hidecasinonavigationlist !== true && (
              <MD>
                <NavListPage
                  isOlympic={appConfig.olympic}
                  productRefs={appConfig.productrefs}
                  casinoTheme={appConfig?.casinotheme}
                  inlineSearch={appConfig?.inlinesearch}
                  reloadPageOnTabChange={appConfig?.reloadpageontabchange}
                />
              </MD>
            )}
          </MDC>
        ) : (
          <>
            <MD
              isSearchIcon={true}
              isEnableSearchTab={isEnableSearchTab}
              inlineSearch={appConfig?.inlinesearch}
            >
              {appConfig?.hidesearchbar !== true && (
                <SearchPage
                  isOlympic={appConfig.olympic}
                  hideCrossIcon={appConfig?.hidecrossicon}
                  searchIcon={appConfig?.playnowbtn}
                  showSearchWithClearButton={
                    appConfig?.showsearchwithclearbutton
                  }
                />
              )}
              {appConfig?.hidecasinofilter !== true && <FilterBtn />}
            </MD>
            {appConfig?.hidecasinonavigationlist !== true && (
              <MD navListBar={true}>
                <NavListPage
                  isOlympic={appConfig.olympic}
                  productRefs={appConfig.productrefs}
                  casinoTheme={appConfig?.casinotheme}
                  inlineSearch={appConfig?.inlinesearch}
                  reloadPageOnTabChange={appConfig?.reloadpageontabchange}
                />
              </MD>
            )}
          </>
        )}
      </MDD>
      {!appConfig.olympic && <BB />}

      <Modal
        data-test="close-modal"
        open={filterModalWindow}
        center
        styles={MStyles}
        cantEscape={true}
        onClose={() => dispatch(toggleFilterModalWindow())}
      >
        <CasinoFilters
          appConfig={appConfig}
          resetGamesList={resetGamesList}
          allGamesInfo={allGamesInfo}
        />
      </Modal>
      {!filterResultDisplay && (
        <FavouriteGame
          appConfig={appConfig}
          playNowBtn={appConfig?.playnowbtn}
          removeGameFromFavourite={removeGameFromFavourite}
          addimgPlaceholderUrl={imgPlaceholderUrl}
        />
      )}
      <GameListingPage
        appConfig={appConfig}
        imgPlaceholderUrl={imgPlaceholderUrl}
        removeGameProvider={removeGameProvider}
      />
    </React.Fragment>
  );
};

AllGamesPage.propTypes = {
  appConfig: PropTypes.object,
  carouselSearchBar: PropTypes.func,
  navigationList: PropTypes.func,
  loadInitialGames: PropTypes.func,
  gotoSelectedGame: PropTypes.func,
  getFavouriteGames: PropTypes.func,
  gamesData: PropTypes.array,
  fetchData: PropTypes.func,
  imgPlaceholderUrl: PropTypes.string,
  hideCrossIcon: PropTypes.bool,
  removeGameFromFavourite: PropTypes.func,
  allGamesInfo: PropTypes.array,
  removeGameProvider: PropTypes.array,
};

export default AllGamesPage;
