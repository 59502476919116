import { StyledEventsLink } from 'CORE__UI/navigation/TopLink/core__topLinkStyles';
import styled, { css } from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';

const StyledEventsLink_OL = styled(StyledEventsLink)`
  color: #909dff;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 20px;
  border: 2px solid ${GREYS.blueBG};
  font-weight: 700;
  @media (hover) {
    &:hover {
      border: 2px solid ${FONT.tertiary};
      box-shadow: 0px 0px 20px #001dffs;
      color: #909dff;
    }
  }
  ${({ active }) =>
    active &&
    css`
      border: 2px solid ${FONT.tertiary};
      box-shadow: 0px 0px 20px #001dffs;
      color: #909dff;
    `}
`;

export { StyledEventsLink_OL as StyledEventsLink };
