import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  gotoSelectedGame,
  navigatetoSelectedGame,
  gotoNextFavSelectionGames,
  gotoPrevFavSelectionGames,
} from '../../../core__all-games-store';
import {
  MD,
  GI,
  THD,
  TCM,
  TC,
  CD,
  NP,
  ED,
  IMAGE,
  HOD,
  HOPD,
  HD,
  DP,
  HPN,
  PN,
  PG,
  TI,
  LD,
  LTD,
  GG as GG_LOCAL,
} from 'CASINO_UI/apps/AllGamesApp/GamesListingpage';
import I from 'UI/globals/Icons';
import { getIcon } from 'Services/icons/core__icons';
import {
  GC,
  GG as GG_CORE,
  ModelStyles,
  HB,
  MP,
  DTN,
  DTD,
  RIBBON,
  RIBBON_TEXT,
} from 'CASINO_CORE__UI/apps/AllGamesApp/core__gamesListingPage';
import { EC } from 'CASINO_CORE__UI/apps/GameLoadingApp/core__gameLoading';
import { FD } from 'CASINO_UI/apps/GameLoadingApp/GameLoading';
import { Translations } from '../../../core__all-games-app';
import { Modal } from 'CORE__UI/globals/Modal/core__modal';
import { CloseIcon } from 'UI/globals/ModalCloseIcon';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getComponent } from 'Services/core__imports';

const GG = getComponent(GG_LOCAL, GG_CORE);

/**
 * this component contains games
 * @param {*} favouriteGamesData array to get all games
 * @param {*} gotoSelectedGame fucntion call to navigate to games
 * @param {*} navigatetoSelectedGame fucntion call to navigate to game page
 * @param {*} gotoNextFavSelectionGames funcion call to go to next games
 * @param {*} gotoPrevFavSelectionGames funcion call to go to prev games
 * @param {*} filterFavouriteGamesData array to get filtered games data
 * */

export class FavouriteGames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      handleModal: false,
      sizerValue: Number,
    };
  }
  resizeListener = () => {
    const WindowWidthTracker = window?.innerWidth;
    this.setState({ sizerValue: WindowWidthTracker });
  };
  componentDidMount() {
    const StartingScreenValue = window.innerWidth;
    this.setState({ sizerValue: StartingScreenValue });

    window.addEventListener('resize', this.resizeListener);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  render() {
    const {
      favouriteGamesData,
      filterFavouriteGamesData,
      activeIcon,
      removeGameFromFavourite,
      appConfig,
      playNowBtn,
    } = this.props;
    const applybuttontoeachtile = appConfig.applybuttontoeachtile;
    const EnableModal = appConfig.enablemodal;

    const onDemoPlayClick = (e, name, id) => {
      e.stopPropagation();
      this.props.navigatetoSelectedGame(name, id, null, 'fun');
    };
    return (
      <MD>
        {(activeIcon === '' ||
          activeIcon === 'Top Lists' ||
          activeIcon === GLOBAL_CONSTANTS.FAVOURITE) && (
          <React.Fragment>
            <THD>
              <TCM>
                {filterFavouriteGamesData.icon && (
                  <TI src={filterFavouriteGamesData.icon} />
                )}
                <TC>
                  {Translations.get(
                    filterFavouriteGamesData?.title
                      ?.toLowerCase()
                      .replaceAll(' ', '')
                  )}
                </TC>
                {filterFavouriteGamesData.gameType?.length > 0 && (
                  <CD>
                    {filterFavouriteGamesData?.gamesCount
                      ? filterFavouriteGamesData.gamesCount
                      : filterFavouriteGamesData?.gameType?.length}
                  </CD>
                )}
              </TCM>
              <TCM>
                {filterFavouriteGamesData?.gamesdisplaycountininitialload && (
                  <React.Fragment>
                    <NP
                      data-test={'label-prevFavSelection'}
                      onClick={() =>
                        this.props.gotoPrevFavSelectionGames(
                          favouriteGamesData,
                          filterFavouriteGamesData
                        )
                      }
                    >
                      {<I iconName={getIcon('CLOSE')} size={28} />}
                    </NP>
                    <NP
                      data-test={'label-nextFavSelection'}
                      onClick={() =>
                        this.props.gotoNextFavSelectionGames(
                          favouriteGamesData,
                          filterFavouriteGamesData
                        )
                      }
                    >
                      {<I iconName={getIcon('OPEN')} size={28} />}
                    </NP>
                  </React.Fragment>
                )}
              </TCM>
            </THD>
            <GG>
              {filterFavouriteGamesData?.gameType?.map((game, ind) => {
                return (
                  <GC key={ind}>
                    <GI
                      data-test={ind === 0 && 'img-favGameclick'}
                      onClick={() => {
                        !EnableModal
                          ? this.props.navigatetoSelectedGame(
                              game?.name,
                              game?.id
                            )
                          : this.state.sizerValue > 900
                          ? this.props.navigatetoSelectedGame(
                              game?.name,
                              game?.id
                            )
                          : this.setState({ handleModal: true });
                      }}
                    >
                      {appConfig.olympic ? (
                        <HPN>
                          <DP
                            showJNModal={true}
                            label={Translations.get('casino.playnow')}
                          />
                          {filterFavouriteGamesData?.hidedemoplay !== true &&
                            filterFavouriteGamesData?.hidedemoplay !==
                              'true' && (
                              <PG
                                onClick={e =>
                                  onDemoPlayClick(e, game?.name, game?.id)
                                }
                                label={Translations.get('casino.demoplay')}
                              />
                            )}
                        </HPN>
                      ) : playNowBtn ? (
                        <HPN>
                          <PN>{GLOBAL_CONSTANTS.PLAY_NOW_L}</PN>
                        </HPN>
                      ) : (
                        <HOPD>
                          <HOD />
                        </HOPD>
                      )}
                      <IMAGE
                        className="lazy-loaded-image lazy"
                        data-src={game?.imageUrl}
                        alt="image"
                        loading="lazy"
                        onError={event => {
                          event.target.onerror = '';
                          event.target.src = game?.baseImageUrl
                            ? game.baseImageUrl
                            : game?.placeHolderImageUrl;
                          return true;
                        }}
                      />
                      {appConfig?.olympic &&
                        filterFavouriteGamesData &&
                        filterFavouriteGamesData?.gameType?.length < 0 &&
                        Array.isArray(game?.displayTags) &&
                        game?.displayTags?.length > 0 && (
                          <LTD>
                            {game?.displayTags?.map((tag, tInd) => {
                              return tag?.toString().toLowerCase() === 'new' ? (
                                <RIBBON tag={tag} key={tInd} indx={tInd}>
                                  <RIBBON_TEXT>{tag}</RIBBON_TEXT>
                                </RIBBON>
                              ) : (
                                <LD tag={tag} key={tInd} indx={tInd}>
                                  {tag}
                                </LD>
                              );
                            })}
                          </LTD>
                        )}
                      {appConfig?.olympic && game?.featuredTag && (
                        <DTN>
                          <DTD>{game?.featuredTag}</DTD>
                        </DTN>
                      )}
                      {applybuttontoeachtile && (
                        <HD
                          data-test={'btn-removeGameclick'}
                          onClick={e => {
                            removeGameFromFavourite(game?.id);
                            e.stopPropagation();
                          }}
                        >
                          <EC>
                            <FD>
                              <I iconName={getIcon('STARACTIVE')} size={28} />
                            </FD>
                          </EC>
                        </HD>
                      )}
                    </GI>
                    {EnableModal && (
                      <Modal
                        data-test="close-modal"
                        open={this.state.handleModal}
                        center
                        styles={ModelStyles}
                        cantEscape={true}
                        onClose={() => this.setState({ handleModal: false })}
                        closeIcon={<CloseIcon />}
                        overlayStyle={{ backgroundColor: '#00000035' }}
                      >
                        <HB>{game?.name}</HB>
                        <MP
                          label={Translations.get('casino.playnow')}
                          onClick={() =>
                            this.props.navigatetoSelectedGame(
                              game?.name,
                              game?.id
                            )
                          }
                        ></MP>
                      </Modal>
                    )}
                  </GC>
                );
              })}
              <ED />
            </GG>
          </React.Fragment>
        )}
      </MD>
    );
  }
}

FavouriteGames.propTypes = {
  favouriteGamesData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  gotoSelectedGame: PropTypes.func,
  navigatetoSelectedGame: PropTypes.func,
  gotoNextFavSelectionGames: PropTypes.func,
  gotoPrevFavSelectionGames: PropTypes.func,
  filterFavouriteGamesData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  appConfig: PropTypes.object,
  activeIcon: PropTypes.string,
  playNowBtn: PropTypes.string,
  removeGameFromFavourite: PropTypes.func,
  applybuttontoeachtile: PropTypes.bool,
};

export const mapStateToProps = state => {
  return {
    favouriteGamesData: state.CasinoGamesData.favouriteGamesData,
    filterFavouriteGamesData: state.CasinoGamesData.filterFavouriteGamesData,
    activeIcon: state.CasinoGamesData.activeIcon,
  };
};

export const mapDispatchToProps = {
  gotoSelectedGame: gotoSelectedGame,
  navigatetoSelectedGame: navigatetoSelectedGame,
  gotoNextFavSelectionGames: gotoNextFavSelectionGames,
  gotoPrevFavSelectionGames: gotoPrevFavSelectionGames,
};

FavouriteGames.defaultProps = {
  favouriteGamesData: [],
  filterFavouriteGamesData: [],
};

export const FavouriteGame = connect(
  mapStateToProps,
  mapDispatchToProps
)(FavouriteGames);
