import { CL, UL } from 'CASINO_CORE__UI/apps/AllGamesApp/core__navigationList';
import styled from 'styled-components';
import { FONT, GREYS } from 'UI/globals/colours';

const UL_OL = styled(UL)`
  align-items: center;
  li a {
    margin-bottom: 0;
    &.navActive {
      border-bottom: 2px solid ${FONT.primary};
      font-weight: 700;
    }
    @media (hover) {
      &:hover {
        font-weight: 700;
        color: ${GREYS.white};
      }
    }
  }
  overflow: auto;
`;

const AD = styled.div`
  margin-top: 8px;
`;

export { CL, UL_OL as UL, AD };
