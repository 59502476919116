import {
  SE,
  BL,
  Input,
  CL,
  TEXT,
} from 'CASINO_CORE__UI/apps/AllGamesApp/core__searchBar';
import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_L } from 'UI/globals/breakpoints';

const SE_OL = styled(SE)`
  display: flex;
  border-radius: 20px;
  padding: 8px 16px;
  width: 100%;
  background-color: ${GREYS.blueL};
  justify-content: flex-start;
  color: ${GREYS.wheat};
  i {
    font-size: 12px;
    align-self: center;
    color: ${FONT.tertiary};
    margin-right: 10px;
  }
  p {
    order: 1;
  }
  margin-top: 8px;
  height: 40px;
  align-items: center;
  @media (min-width: ${BREAKPOINT_L}) {
    max-width: 240px;
  }
`;

const BL_OL = styled(BL)`
  color: ${GREYS.white};
  border-right: 1px solid ${GREYS.white};
  padding-right: 8px;
`;

const CL_OL = styled(CL)`
  color: ${GREYS.white};
  border-radius: 6px;
  border: 1px solid white;
  padding: 0;
  align-self: center;
`;

const Input_OL = styled(Input)`
  background-color: transparent;
  padding: 0;
  color: ${GREYS.white};
  ::placeholder {
    color: ${GREYS.white};
    opacity: 1;
  }
`;

const SD = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${GREYS.blueL};
  border-radius: 20px;
  padding: 8px 16px;
  color: #909dff;
  margin-top: 8px;
  height: 40px;
`;

export { SE_OL as SE, BL_OL as BL, Input_OL as Input, CL_OL as CL, SD, TEXT };
