import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from 'Services/http/core__axios';
import { getCookie } from 'Services/cookie/core__cookies';
import Constants from '../../../project';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import PubSub from 'Services/pubsub/core__pubsub';

import { gamesFilterReducer } from './core__all-games-filter-reducer';
import { filterInitialState } from './core__all-games-filter-initialstate';
import {
  setOptionsFeatures,
  setOptionsGameTypes,
  setOptionsMaxWinMultipliers,
  setOptionsVolatilitys,
  setOptionsProviders,
} from './core__all-games-filter-actions';
import { SiteURL } from 'Services/core__site-url';
import { CasinoUrlService } from 'Services/url-amendments/core__url-amendments';
import {
  stringToArray,
  getLastRoute,
} from 'Services/globalfunctions/core__global-functions';

const intialHeight = 1000;

const initialStateTemplate = {
  CasinoGamesData: {
    isshowCasinoSearch: false,
    navigationItems: [],
    isEnableSearchTab: false,
    getSearchValue: '',
    activeIcon: '',
    gamesData: [],
    filterGamesData: [],
    favouriteGamesData: {},
    favouriteGamesId: {},
    filterFavouriteGamesData: {},
    gamesDataCopy: [],
    allGamesInfo: [],
  },
  ...filterInitialState,
};

export const gamesReducer = (state = {}, action) => {
  switch (action.type) {
    // Enable/disable search app
    case 'SHOW_SEARCH_APP':
      return { ...state, isshowCasinoSearch: action.isEnable };
    // Enable/disable search app
    case 'SET_NAVIGATION_LINKS':
      return { ...state, navigationItems: action.navData };
    // Enable/disable search tab
    case 'ENABLE_SEARCH_TAB':
      return { ...state, isEnableSearchTab: action.isEnable };
    // Set search value
    case 'SET_SEARCH_VALUE':
      return { ...state, getSearchValue: action.val };
    // Set active icon
    case 'SET_ACTIVE_ICON':
      return { ...state, activeIcon: action.getIcon };
    // Store games
    case 'STORE_INITIAL_LOAD_GAMES':
      return { ...state, gamesData: action.games };
    // Store filtered games data
    case 'FILTERED_GAMES_DATA':
      return { ...state, filterGamesData: action.games };
    // Set favourite games
    case 'SET_FAVOURITE_GAMES':
      return { ...state, favouriteGamesData: action.favGames };
    // Store filtered fav games
    case 'STORE_FILTERED_FAV_GAMES':
      return { ...state, filterFavouriteGamesData: action.favGames };
    // Store games copy
    case 'INITIAL_LOAD_GAMES_COPY':
      return { ...state, gamesDataCopy: action.games };
    // games data
    case 'ALL_GAMES_DATA':
      return { ...state, allGamesInfo: action.games };
    default:
      return state;
  }
};
//Combine reducers into a single reducer
export const reducer = combineReducers({
  CasinoGamesData: gamesReducer,
  CasinoFilterData: gamesFilterReducer,
});

// Enable casino search app
export const showSearchApp = isEnable => ({
  type: 'SHOW_SEARCH_APP',
  isEnable,
});
// Set navigation items
export const setNavigationLinks = navData => ({
  type: 'SET_NAVIGATION_LINKS',
  navData,
});
// Enable search tab
export const enableSearchTab = isEnable => ({
  type: 'ENABLE_SEARCH_TAB',
  isEnable,
});
// Set search value
export const setSearchValue = val => ({
  type: 'SET_SEARCH_VALUE',
  val,
});
// Set active icon
export const setActiveIcon = getIcon => ({
  type: 'SET_ACTIVE_ICON',
  getIcon,
});
// Store games
export const storeInitialLoadGames = games => ({
  type: 'STORE_INITIAL_LOAD_GAMES',
  games,
});
// Store filtered games
export const filteredGamesData = games => ({
  type: 'FILTERED_GAMES_DATA',
  games,
});
// Set favourite games
export const setFavouriteGames = favGames => ({
  type: 'SET_FAVOURITE_GAMES',
  favGames,
});
// Store filtered fav games
export const storeFilteredFavGames = favGames => ({
  type: 'STORE_FILTERED_FAV_GAMES',
  favGames,
});
// Store games copy
export const initialLoadGamesCopy = games => ({
  type: 'INITIAL_LOAD_GAMES_COPY',
  games,
});
// Store games copy
export const allGamesData = games => ({
  type: 'ALL_GAMES_DATA',
  games,
});
/**
 * Method to show search bar
 */
export const carouselSearchBar = () => dispatch => {
  const getAllAtributes = document.querySelectorAll(
    '[data-app="CasinoSearchApp"]'
  );
  if (getAllAtributes?.length > 0) {
    dispatch(showSearchApp(true));
  }
};
/**
 * Method to get Navigation list
 */
export const navigationList = () => dispatch => {
  const navigationItems = [];
  const getAllAtributes = document.querySelectorAll(
    '[data-app="CasinoNavigationApp"]'
  );
  for (let n = 0; n < getAllAtributes.length; n++) {
    const params = getAllAtributes[n]?.dataset;
    const getNavIcons = params?.navigationicons?.split(',');
    const getNavLinks = params?.navigationlinks?.split(',');
    let gameIcons;
    if (params?.gameicons) {
      gameIcons = params?.gameicons?.split(',');
    }
    if (getNavIcons?.length > 0 && getNavLinks?.length > 0) {
      for (let i = 0; i < getNavIcons.length; i++) {
        navigationItems.push({
          navIcon: getNavIcons[i],
          navLink: getNavLinks[i],
          gameIcon: gameIcons && gameIcons[i],
        });
      }
    }
  }
  if (navigationItems.length > 0) {
    dispatch(setActiveIcon(navigationItems[0].navIcon));
  }
  dispatch(setNavigationLinks(navigationItems));
};
/**
 * Method to enable search tab
 */
export const searchIconClick = () => dispatch => {
  dispatch(enableSearchTab(true));
};
/**
 * Method to disable search tab
 */
export const backIconClick = allGames => dispatch => {
  dispatch(enableSearchTab(false));
  dispatch(storeInitialLoadGames(allGames));
  dispatch(setSearchValue(''));
};
/**
 * Method to set search value
 */
export const storeSearchValue = (value, data, favGamesData) => dispatch => {
  dispatch(setSearchValue(value));
  const getValue = value.toLowerCase().replace(/ /g, '');
  const allGames =
    data &&
    data.map(item => {
      const filteredGameType = (item.gameType || []).filter(game => {
        const gameName = game.name && game.name.toLowerCase().replace(/ /g, '');
        return gameName && gameName.includes(getValue);
      });
      return { ...item, gameType: filteredGameType };
    });
  dispatch(storeInitialLoadGames(allGames));
  const allFavGames = favGamesData && {
    ...favGamesData,
    gameType: (favGamesData.gameType || []).filter(game => {
      const favGameName =
        game.name && game.name.toLowerCase().replace(/ /g, '');
      return favGameName && favGameName.includes(getValue);
    }),
  };

  dispatch(storeFilteredFavGames(allFavGames));
};

/**
 * Method to fetch data from server
 */
export const fetchData = (url, nodeRequest, appConfig) => dispatch => {
  const promises = [];
  promises.push(loadInitialGames(nodeRequest, appConfig, dispatch));
  return Promise.all(promises);
};

/**
 * Method to get filter options
 */
export const loadFilterOptions = allGamesInfo => dispatch => {
  var newOptionsGameTypes = [];
  var newOptionsMaxWinMultipliers = [];
  var newOptionsVolatilitys = [];
  var newOptionsFeatures = [];
  var newOptionsProviders = [];
  allGamesInfo?.forEach(game => {
    newOptionsGameTypes.push(game.product.ref);
    game.metadata?.forEach(metadata => {
      metadata.name === 'maxWinMultiplier' &&
        newOptionsMaxWinMultipliers.push(metadata.value);
      metadata.name === 'features' && newOptionsFeatures.push(metadata.value);
      metadata.name === 'volatility' &&
        newOptionsVolatilitys.push(metadata.value);
      metadata.name === 'developer' && newOptionsProviders.push(metadata.value);
    });
  });
  dispatch(setOptionsFeatures(newOptionsFeatures.filter(onlyUnique)));
  dispatch(setOptionsGameTypes(newOptionsGameTypes.filter(onlyUnique)));
  dispatch(
    setOptionsMaxWinMultipliers(newOptionsMaxWinMultipliers.filter(onlyUnique))
  );
  dispatch(setOptionsVolatilitys(newOptionsVolatilitys.filter(onlyUnique)));
  dispatch(setOptionsProviders(newOptionsProviders.filter(onlyUnique)));
};

/**
 * Method to get initial load games
 */
export const loadInitialGames = (nodeRequest, appConfig, dispatch) => {
  const requester = !nodeRequest ? getRequest : nodeRequest;
  let domainUrl;
  if (typeof window !== 'undefined') {
    domainUrl = window.location.pathname;
  } else {
    domainUrl = appConfig?.requestURL;
  }
  let currentPage = '';
  if (domainUrl.indexOf('/casino/') > -1) {
    currentPage = domainUrl.split('/casino/')[1];
  } else if (domainUrl.indexOf('/casino') > -1) {
    currentPage = domainUrl.split('/casino')[1];
  }
  if (currentPage === '') {
    let initialLoadGames = [];
    let getAllAtributes;
    if (nodeRequest) {
      getAllAtributes = appConfig?.items;
    } else {
      getAllAtributes = document.querySelectorAll('[data-app="GamesApp"]');
    }
    for (let g = 0; g < getAllAtributes?.length; g++) {
      let params;
      if (nodeRequest) {
        params = getAllAtributes[g]?.appConfig;
      } else {
        params = getAllAtributes[g]?.dataset;
      }
      if (params) {
        initialLoadGames[g] = [];
        initialLoadGames[g]['title'] = params?.title;
        initialLoadGames[g]['icon'] = params?.icon;
        initialLoadGames[g]['product'] =
          params?.product !== '' && params?.product?.split(',');
        initialLoadGames[g]['tagstodisplay'] =
          params?.tagstodisplay !== '' && params?.tagstodisplay?.split(',');
        initialLoadGames[g]['tagsenabled'] = params?.tagsenabled;
        initialLoadGames[g]['tilesclass'] = params?.tilesclass;
        initialLoadGames[g]['displayonlygameswithspecifictags'] =
          params?.displayonlygameswithspecifictags !== '' &&
          params?.displayonlygameswithspecifictags?.split(',');
        initialLoadGames[g]['gametohighlight'] = params?.gametohighlight?.split(
          ','
        );
        initialLoadGames[g]['gameref'] = params?.gameref;
        initialLoadGames[g]['buttontext'] = params?.buttontext;
        initialLoadGames[g]['gameproviderid'] =
          params?.gameproviderid !== '' && params?.gameproviderid?.split(',');
        initialLoadGames[g]['gamesdisplaycountininitialload'] =
          params?.gamesdisplaycountininitialload;
        initialLoadGames[g]['featuredtag'] = params?.featuredtag;
        initialLoadGames[g]['tagcolours'] = params?.tagcolours;
        initialLoadGames[g]['gameoptions'] = params?.gameoptions;
      }
    }
    let domain;
    if (nodeRequest) {
      domain = '.json';
    } else {
      domain = '.fsb';
    }
    const url = `/fsb-api-rest/superSiteGameType` + domain;
    return requester(url).then(getGames => {
      dispatch(allGamesData(getGames?.gameType));
      getGames?.gameType?.forEach(game => delete game?.summary);
      initialLoadGames = filterInitialLoadGames(getGames, initialLoadGames);
      const storeInitialGames = initialLoadGames?.map(_arrayElement =>
        Object.assign({}, _arrayElement)
      );
      const storeAllGames = initialLoadGames?.map(_arrayElement =>
        Object.assign({}, _arrayElement)
      );
      for (let a = 0; a < storeAllGames?.length; a++) {
        if (storeAllGames[a]?.gamesdisplaycountininitialload) {
          storeAllGames[a]['gamesCount'] = storeAllGames[a].gameType?.length;
          const gameCount = Number(
            storeAllGames[a]?.gamesdisplaycountininitialload
          );
          storeAllGames[a].gameType = storeAllGames[a].gameType?.slice(
            0,
            gameCount
          );
          storeAllGames[a]['filteredCount'] = gameCount;
          initialLoadGames[a]['filteredCount'] = gameCount;
        }
      }
      dispatch(storeInitialLoadGames(storeAllGames));
      dispatch(filteredGamesData(storeInitialGames));
      dispatch(initialLoadGamesCopy(storeAllGames));
    });
  }
};

/**
 * Method to filter initial load games
 */
function filterInitialLoadGames(getGames, initialLoadGames, from) {
  if (Array.isArray(getGames?.gameType)) {
    for (const [p, getProduct] of initialLoadGames.entries()) {
      if (from === GLOBAL_CONSTANTS.SELECTEDGAME) {
        getProduct['showViewButton'] = false;
      } else {
        getProduct['showViewButton'] = true;
      }
      if (Array.isArray(getProduct?.displayonlygameswithspecifictags)) {
        let ind = -1;
        for (const game of getGames?.gameType) {
          game['order'] = game?.gameTypeSuperSite?.ordinal;
          if (Array.isArray(game.metadata) && game.metadata.length > 0) {
            for (let g = 0; g < game.metadata.length; g++) {
              if (game.metadata[g].name === 'imageThumbnail') {
                game.imageThumbnail = game.metadata[g].value;
              }
              if (game.metadata[g].name === 'backgroundImage') {
                game['baseImageUrl'] = game.metadata[g].value;
              }
            }
          }
          const getImageUrl = buildImageUrl(game);
          game['imageUrl'] = game.imageThumbnail
            ? game.imageThumbnail
            : getImageUrl?.imageUrl;
          game['placeHolderImageUrl'] = getImageUrl?.placeHolderImageUrl;
          const UserAuthData = FSBCustomerSession.getSession();
          const currencyCode = UserAuthData
            ? UserAuthData.currencyCode
            : Constants.currency;
          if (Array.isArray(game.metadata) && game.metadata.length > 0) {
            for (let m = 0; m < game.metadata.length; m++) {
              if (
                game.metadata[m].name ===
                'megaJackpotValue_' + currencyCode
              ) {
                game['jackpotAmount'] = game.metadata[m].value
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }
          }
          if (game?.tag) {
            const tagArray = game?.tag?.split(',');
            for (let a = 0; a < tagArray.length; a++) {
              if (tagArray[a].indexOf('Live') > -1) {
                game['isLiveGame'] = true;
              }
            }
            let isTag = false;
            for (let t = 0; t < tagArray.length; t++) {
              if (
                getProduct?.displayonlygameswithspecifictags &&
                getProduct?.displayonlygameswithspecifictags.indexOf(
                  tagArray[t]
                ) > -1
              ) {
                isTag = true;
              }
            }
            if (isTag) {
              if (
                !Array.isArray(getProduct?.gameproviderid) &&
                Array.isArray(getProduct?.product) &&
                getProduct?.product?.length > 0
              ) {
                for (let g = 0; g < getProduct?.product?.length; g++) {
                  if (getProduct?.product[g] === game?.product?.ref) {
                    const getFilterData = gamesFilterByProperty(
                      ind,
                      initialLoadGames,
                      p,
                      getProduct,
                      game
                    );
                    initialLoadGames = getFilterData.initialLoadGames;
                    ind = getFilterData.ind;
                  }
                }
              } else if (
                Array.isArray(getProduct?.gameproviderid) &&
                getProduct?.gameproviderid?.length > 0
              ) {
                for (let g = 0; g < getProduct?.gameproviderid?.length; g++) {
                  if (
                    Number(getProduct?.gameproviderid[g]) ===
                    Number(game?.gameProvider?.id)
                  ) {
                    if (
                      Array.isArray(getProduct?.product) &&
                      getProduct?.product?.length > 0
                    ) {
                      for (let c = 0; c < getProduct?.product?.length; c++) {
                        if (getProduct?.product[c] === game?.product?.ref) {
                          const getFilterData = gamesFilterByProperty(
                            ind,
                            initialLoadGames,
                            p,
                            getProduct,
                            game
                          );
                          initialLoadGames = getFilterData.initialLoadGames;
                          ind = getFilterData.ind;
                        }
                      }
                    } else {
                      const getFilterData = gamesFilterByProperty(
                        ind,
                        initialLoadGames,
                        p,
                        getProduct,
                        game
                      );
                      initialLoadGames = getFilterData.initialLoadGames;
                      ind = getFilterData.ind;
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        let ind = -1;
        for (const game of getGames?.gameType) {
          const UserAuthData = FSBCustomerSession.getSession();
          const currencyCode = UserAuthData
            ? UserAuthData.currencyCode
            : Constants.currency;
          if (Array.isArray(game.metadata) && game.metadata.length > 0) {
            for (let m = 0; m < game.metadata.length; m++) {
              if (
                game.metadata[m].name ===
                'megaJackpotValue_' + currencyCode
              ) {
                game['jackpotAmount'] = game.metadata[m].value
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              }
            }
          }
          if (game?.tag) {
            const tagArray = game?.tag?.split(',');
            for (let t = 0; t < tagArray.length; t++) {
              if (tagArray[t].indexOf('Live') > -1) {
                game['isLiveGame'] = true;
              }
            }
          }
          game['order'] = game?.gameTypeSuperSite?.ordinal;
          if (Array.isArray(game.metadata) && game.metadata.length > 0) {
            for (let g = 0; g < game.metadata.length; g++) {
              if (game.metadata[g].name === 'imageThumbnail') {
                game.imageThumbnail = game.metadata[g].value;
              }
              if (game.metadata[g].name === 'backgroundImage') {
                game['baseImageUrl'] = game.metadata[g].value;
              }
            }
          }
          const getImageUrl = buildImageUrl(game);
          game['imageUrl'] = game.imageThumbnail
            ? game.imageThumbnail
            : getImageUrl?.imageUrl;
          game['placeHolderImageUrl'] = getImageUrl?.placeHolderImageUrl;
          if (
            !Array.isArray(getProduct?.gameproviderid) &&
            Array.isArray(getProduct?.product) &&
            getProduct?.product?.length > 0
          ) {
            for (let g = 0; g < getProduct?.product?.length; g++) {
              if (getProduct?.product[g] === game?.product?.ref) {
                const getFilterData = gamesFilterByProperty(
                  ind,
                  initialLoadGames,
                  p,
                  getProduct,
                  game
                );
                initialLoadGames = getFilterData.initialLoadGames;
                ind = getFilterData.ind;
              }
            }
          } else if (
            Array.isArray(getProduct?.gameproviderid) &&
            getProduct?.gameproviderid?.length > 0
          ) {
            for (let g = 0; g < getProduct?.gameproviderid?.length; g++) {
              if (
                Number(getProduct?.gameproviderid[g]) ===
                Number(game?.gameProvider?.id)
              ) {
                if (
                  Array.isArray(getProduct?.product) &&
                  getProduct?.product?.length > 0
                ) {
                  for (let c = 0; c < getProduct?.product?.length; c++) {
                    if (getProduct?.product[c] === game?.product?.ref) {
                      const getFilterData = gamesFilterByProperty(
                        ind,
                        initialLoadGames,
                        p,
                        getProduct,
                        game
                      );
                      initialLoadGames = getFilterData.initialLoadGames;
                      ind = getFilterData.ind;
                    }
                  }
                } else {
                  const getFilterData = gamesFilterByProperty(
                    ind,
                    initialLoadGames,
                    p,
                    getProduct,
                    game
                  );
                  initialLoadGames = getFilterData.initialLoadGames;
                  ind = getFilterData.ind;
                }
              }
            }
          }
        }
      }
    }
    return initialLoadGames;
  }
}
/**
 * Method to get Image URL
 */
export function buildImageUrl(game) {
  const baseCasinoImagesURL = Constants?.casino?.assetsUrl;
  const remoteRef = game?.remoteRef;
  const gameProviderRef = game?.gameProvider?.ref;
  const product = game?.product?.ref;
  let imageUrl;
  switch (gameProviderRef) {
    case 'NYX':
    case 'NETENT':
    case 'ELITE': {
      const stringifiedName = game?.name.replace(
        new RegExp(String.fromCharCode(160), 'g'),
        ' '
      );
      const name = stringifiedName.split(' ').join('_').replace("'", '', 'g');
      imageUrl = `${baseCasinoImagesURL}/casino-game-thumb-${gameProviderRef}_${name}.jpg`;
      break;
    }
    case 'EVOLUTION':
      imageUrl = `${baseCasinoImagesURL}/casino-game-thumb-${gameProviderRef}_${game?.name.replace(
        / /g,
        '_'
      )}.jpg`;
      break;
    default:
      imageUrl = `${baseCasinoImagesURL}/casino-game-thumb-${remoteRef}.jpg`;
  }
  return {
    placeHolderImageUrl: `${baseCasinoImagesURL}/casino-game-thumb-${product?.toLowerCase()}.jpg`,
    imageUrl,
  };
}
/**
 * Method to filter games
 */
function gamesFilterByProperty(ind, initialLoadGames, p, getProduct, game) {
  if (Array.isArray(initialLoadGames[p]['gameType'])) {
    initialLoadGames[p]['gameType'].push(JSON.parse(JSON.stringify(game)));
  } else {
    initialLoadGames[p]['gameType'] = [];
    initialLoadGames[p]['gameType'].push(JSON.parse(JSON.stringify(game)));
  }
  ind = ind + 1;
  if (getProduct?.tagsenabled === true || getProduct?.tagsenabled === 'true') {
    const displayTags = [];
    if (
      Array.isArray(getProduct?.tagstodisplay) &&
      getProduct?.tagstodisplay?.length > 0
    ) {
      for (const tagList of getProduct?.tagstodisplay) {
        const gameTags = game?.tag && game?.tag?.split(',');
        if (Array.isArray(gameTags)) {
          for (const getTag of gameTags) {
            if (tagList.toLowerCase() === getTag.toLowerCase()) {
              displayTags.push(tagList);
            }
          }
        }
      }
    } else {
      const gameTags = game?.tag && game?.tag?.split(',');
      if (Array.isArray(gameTags)) {
        for (const getTag of gameTags) {
          displayTags.push(getTag);
        }
      }
    }
    let getFeaturedTag = '';
    if (getProduct?.featuredtag) {
      const getTags = game?.tag && game?.tag?.split(',');
      if (Array.isArray(getTags)) {
        for (const getTag of getTags) {
          if (getProduct.featuredtag.toLowerCase() === getTag.toLowerCase()) {
            getFeaturedTag = getProduct.featuredtag;
          }
        }
      }
    }
    const tagColours = [];
    const getTagColours = getProduct?.tagcolours;
    const coloursObj = [];
    if (getTagColours) {
      const splitData = getTagColours.split(',');
      for (const getObj of splitData) {
        const splitObj = getObj.split(':');
        if (splitObj.length > 1) {
          coloursObj.push({
            label: splitObj[0],
            code: splitObj[1],
          });
        }
      }
    }
    if (displayTags.length > 0) {
      for (const [t, tag] of displayTags.entries()) {
        let setColour = '';
        for (const colour of coloursObj) {
          if (colour?.label?.toLowerCase() === tag?.toLowerCase()) {
            setColour = colour.code;
          }
        }
        tagColours[t] = setColour;
      }
    }
    if (ind >= 0) {
      initialLoadGames[p].gameType[ind]['displayTags'] = JSON.parse(
        JSON.stringify(displayTags)
      );
      initialLoadGames[p].gameType[ind]['featuredTag'] = JSON.parse(
        JSON.stringify(getFeaturedTag)
      );
      initialLoadGames[p].gameType[ind]['tagColours'] = JSON.parse(
        JSON.stringify(tagColours)
      );
    }
  }
  const gameOptions = getGameOptions(getProduct);
  if (ind >= 0) {
    initialLoadGames[p].gameType[ind]['gameOptions'] = JSON.parse(
      JSON.stringify(gameOptions)
    );
  }
  if (Array.isArray(getProduct?.gametohighlight)) {
    for (const gameHighlight of getProduct?.gametohighlight) {
      if (gameHighlight === game?.name) {
        initialLoadGames[p].gameType[ind]['isHighlight'] = true;
        initialLoadGames[p].gameType[ind]['order'] = -1;
      }
    }
  }
  return { initialLoadGames: initialLoadGames, ind: ind };
}

/**
 * Method to get gameOptions from product
 * @param { object } property
 */
export const getGameOptions = getProduct => {
  let gameOptions = [];
  if (getProduct?.gameoptions && getProduct?.gameoptions.indexOf(',') !== -1) {
    const splitOptions = getProduct.gameoptions.split(',');
    for (const option of splitOptions) {
      gameOptions.push(option);
    }
  } else if (getProduct?.gameoptions) {
    gameOptions.push(getProduct.gameoptions);
  } else {
    gameOptions = [];
  }
  return gameOptions;
};

/**
 * Method to navigate to selected game
 */
export const gotoSelectedGame = value => dispatch => {
  const getPath = window.location.pathname;
  const lastRoute = getLastRoute(window.location.href);
  if (value === GLOBAL_CONSTANTS.TOPLISTSCASINO) {
    window.localStorage.removeItem('refresh');
    let pageUrl;
    const currentPageUrl = window.location.href.split('/');
    if (lastRoute !== GLOBAL_CONSTANTS.S_CASINO) {
      currentPageUrl.splice(-2);
      pageUrl = currentPageUrl.join('/') + '/';
    } else {
      pageUrl = window.location.href;
    }
    window.location.href = pageUrl;
  } else {
    let currentPage = '';
    let getParam = '';
    if (getPath.indexOf('/casino/') > -1) {
      currentPage = getPath.split('/casino/')[1];
    } else if (getPath.indexOf('/casino') > -1) {
      currentPage = getPath.split('/casino')[1];
    }
    currentPage = currentPage?.replace('/', '');
    if (currentPage !== '' || value) {
      if (value) {
        getParam = value;
        let pageUrl;
        const currentPageUrl = window.location.href.split('/');
        if (lastRoute && lastRoute !== GLOBAL_CONSTANTS.S_CASINO) {
          currentPageUrl.splice(-2);
          pageUrl = `${currentPageUrl.join('/')}/${value}/`;
        } else {
          pageUrl = `${window.location.origin}${SiteURL.path}/casino/${value}/`;
        }
        window.history.pushState({}, document.title, pageUrl);
        const getScrollId = document.getElementById('pageScrollToTop');
        if (getScrollId !== null) {
          getScrollId.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else {
        getParam = currentPage;
      }
      let initialLoadGames = [];
      const getAllAtributes = document.querySelectorAll(
        '[data-gameref="' + getParam + '"]'
      );
      for (let g = 0; g < getAllAtributes.length; g++) {
        const params = getAllAtributes[g]?.dataset;
        if (params) {
          initialLoadGames[g] = [];
          initialLoadGames[g]['title'] = params?.title;
          initialLoadGames[g]['icon'] = params?.icon;
          initialLoadGames[g]['product'] =
            params?.product !== '' && params?.product?.split(',');
          initialLoadGames[g]['tagstodisplay'] =
            params?.tagstodisplay !== '' && params?.tagstodisplay?.split(',');
          initialLoadGames[g]['tagsenabled'] = params?.tagsenabled;
          initialLoadGames[g]['tilesclass'] = params?.tilesclass;
          initialLoadGames[g]['displayonlygameswithspecifictags'] =
            params?.displayonlygameswithspecifictags !== '' &&
            params?.displayonlygameswithspecifictags?.split(',');
          initialLoadGames[g][
            'gametohighlight'
          ] = params?.gametohighlight?.split(',');
          initialLoadGames[g]['gameref'] = params?.gameref;
          initialLoadGames[g]['buttontext'] = params?.buttontext;
          initialLoadGames[g]['gameproviderid'] =
            params?.gameproviderid !== '' && params?.gameproviderid?.split(',');
          initialLoadGames[g]['gamesdisplaycountininitialload'] =
            params?.gamesdisplaycountininitialload;
          initialLoadGames[g]['multipleappsload'] = params?.multipleappsload;
          initialLoadGames[g]['featuredtag'] = params?.featuredtag;
          initialLoadGames[g]['tagcolours'] = params?.tagcolours;
          initialLoadGames[g]['gameoptions'] = params?.gameoptions;
        }
      }
      const param = getParam?.replace('-', ' ');
      dispatch(setActiveIcon(param));
      let multipleAppsLoad = false;
      for (let p = 0; p < initialLoadGames.length; p++) {
        if (initialLoadGames[p].multipleappsload === 'true') {
          multipleAppsLoad = true;
        }
      }
      if (initialLoadGames.length >= 0 && !multipleAppsLoad) {
        window.localStorage.removeItem('refresh');
        const baseUrl = `/fsb-api-rest/superSiteGameType.fsb`;
        return getRequest(baseUrl).then(getGames => {
          initialLoadGames = filterInitialLoadGames(
            getGames,
            initialLoadGames,
            'selectedGame'
          );
          dispatch(storeInitialLoadGames(initialLoadGames));
          dispatch(filteredGamesData(initialLoadGames));
          dispatch(initialLoadGamesCopy(initialLoadGames));
          dispatch(allGamesData(getGames?.gameType));
        });
      } else {
        dispatch(storeInitialLoadGames(initialLoadGames));
        dispatch(filteredGamesData(initialLoadGames));
        dispatch(initialLoadGamesCopy(initialLoadGames));
        const refresh = window.localStorage.getItem('refresh');
        if (refresh === null) {
          window.location.reload();
          window.localStorage.setItem('refresh', '1');
        }
      }
    }
  }
};
/**
 * Method to navigate to game play page
 */
export const navigatetoSelectedGame = (
  gameName,
  gameId,
  iframeNav,
  mode,
  productRef,
  urlAmendments,
  demoPlayActiveLink
) => () => {
  const name = gameName.replace(/ /g, '_');
  let url;
  if (mode === 'fun') {
    url =
      window.location.origin +
      '/casino/game/' +
      decodeURIComponent(name) +
      '/?id=' +
      gameId +
      '&mode=fun';
  } else {
    url =
      window.location.origin +
      SiteURL.path +
      '/casino/game/' +
      decodeURIComponent(name) +
      '/?id=' +
      gameId;
  }
  if (urlAmendments) {
    const data = {
      productRef: productRef,
      gameName: decodeURIComponent(name),
      gameId: gameId,
    };
    url = CasinoUrlService.createUrlForTheBrowser(data);
  }
  //iframeNav variable works for casino in iframe condition i.e for GOAT
  if (iframeNav) {
    const UserAuthData = FSBCustomerSession.getSession();
    const path = '/casino/game/' + decodeURIComponent(name) + '/?id=' + gameId;
    let message = {
      action: GLOBAL_CONSTANTS.IFRAME_NAVIGATION,
      path,
    };
    window.parent.postMessage(message, '*');
    message = {
      action: GLOBAL_CONSTANTS.IFRAME_SIZE,
      height: intialHeight,
    };
    if (UserAuthData && UserAuthData['accessToken']) {
      window.parent.postMessage(message, '*');
    }
  } else {
    if (!getCookie('AppSession')) {
      if (mode === 'fun' && demoPlayActiveLink) {
        window.location.href = url;
      } else {
        PubSub.emit(PubsubEvents.callLoginModalFromCasino, {
          modal: true,
          page: 'fromCasino',
          url: url,
        });
      }
    } else {
      window.location.href = url;
    }
  }
};
/**
 * Method to get next set of games
 */
export const gotoNextSelectionGames = (
  allGamesData,
  ind,
  gamesData
) => dispatch => {
  const initialLoadGames = allGamesData;
  const nextGamesData = gamesData?.map(_arrayElement =>
    Object.assign({}, _arrayElement)
  );
  let nextGamesDisplayCount;
  if (nextGamesData[ind].gamesdisplaycountininitialload) {
    const displayCount = Number(
      nextGamesData[ind].gamesdisplaycountininitialload
    );
    const getFilteredCount = Number(nextGamesData[ind].filteredCount);
    if (
      getFilteredCount !== initialLoadGames[ind]?.gameType?.length &&
      initialLoadGames[ind]?.gameType?.length > displayCount
    ) {
      nextGamesDisplayCount = Number(displayCount) + Number(getFilteredCount);
    }
    if (
      Array.isArray(nextGamesData[ind].gametohighlight) &&
      nextGamesData[ind].gametohighlight.length > 0 &&
      Number(nextGamesData[ind].gamesdisplaycountininitialload) === 9
    ) {
      nextGamesDisplayCount = Number(nextGamesDisplayCount) + 3;
    }
    if (nextGamesDisplayCount <= initialLoadGames[ind]?.gameType?.length) {
      nextGamesData[ind].gameType = initialLoadGames[ind].gameType.slice(
        getFilteredCount,
        nextGamesDisplayCount
      );
      nextGamesData[ind].filteredCount = nextGamesDisplayCount;
      initialLoadGames[ind].filteredCount = nextGamesDisplayCount;
    } else {
      if (nextGamesDisplayCount > 0) {
        const lastPageGamesCount =
          Number(nextGamesDisplayCount) -
          Number(initialLoadGames[ind]?.gameType?.length);
        if (lastPageGamesCount > 0) {
          nextGamesDisplayCount =
            Number(nextGamesDisplayCount) - Number(lastPageGamesCount);
          nextGamesData[ind].gameType = initialLoadGames[ind].gameType.slice(
            getFilteredCount,
            nextGamesDisplayCount
          );
          nextGamesData[ind].filteredCount = nextGamesDisplayCount;
          initialLoadGames[ind].filteredCount = nextGamesDisplayCount;
          nextGamesData[ind]['lastPageCount'] =
            nextGamesData[ind]?.gameType?.length;
          initialLoadGames[ind]['lastPageCount'] =
            nextGamesData[ind]?.gameType?.length;
        }
      }
    }
    for (let n = 0; n < nextGamesData.length; n++) {
      nextGamesData[n]['gameSpecificNextClick'] = true;
      nextGamesData[n]['gameSpecificRef'] = '';
    }
    nextGamesData[ind]['gameSpecificRef'] = nextGamesData[ind]?.gameref;
  }
  if (nextGamesDisplayCount > 0) {
    dispatch(storeInitialLoadGames(nextGamesData));
    dispatch(filteredGamesData(initialLoadGames));
    dispatch(initialLoadGamesCopy(nextGamesData));
  }
};

/**
 * Method to get prev set of games
 */
export const gotoPrevSelectionGames = (
  allGamesData,
  ind,
  gamesData
) => dispatch => {
  const initialLoadGames = allGamesData;
  const prevGamesData = gamesData?.map(_arrayElement =>
    Object.assign({}, _arrayElement)
  );
  let prevGamesDisplayCount;
  if (prevGamesData[ind].gamesdisplaycountininitialload) {
    const displayCount = Number(
      prevGamesData[ind].gamesdisplaycountininitialload
    );
    let getFilteredCount = Number(prevGamesData[ind].filteredCount);
    if (prevGamesData[ind]?.lastPageCount) {
      prevGamesDisplayCount =
        Number(getFilteredCount) - Number(prevGamesData[ind]?.lastPageCount);
      getFilteredCount =
        Number(getFilteredCount) - Number(prevGamesData[ind]?.lastPageCount);
      prevGamesDisplayCount = prevGamesDisplayCount - Number(displayCount);
      if (prevGamesDisplayCount >= 3) {
        prevGamesDisplayCount = getGamesCount(
          prevGamesData[ind],
          prevGamesDisplayCount
        );
      }
      prevGamesData[ind].lastPageCount = '';
      initialLoadGames[ind].lastPageCount = '';
    } else {
      prevGamesDisplayCount = Number(getFilteredCount) - Number(displayCount);
      getFilteredCount = Number(getFilteredCount) - Number(displayCount);
      if (
        Array.isArray(prevGamesData[ind].gametohighlight) &&
        prevGamesData[ind].gametohighlight.length > 0 &&
        Number(prevGamesData[ind].gamesdisplaycountininitialload) === 9
      ) {
        getFilteredCount = Number(getFilteredCount) - 3;
        prevGamesDisplayCount = Number(prevGamesDisplayCount) - 3;
      }
      prevGamesDisplayCount = prevGamesDisplayCount - Number(displayCount);
      if (prevGamesDisplayCount >= 3) {
        prevGamesDisplayCount = getGamesCount(
          prevGamesData[ind],
          prevGamesDisplayCount
        );
      }
    }
    if (
      prevGamesDisplayCount >= 0 &&
      prevGamesDisplayCount <= initialLoadGames[ind]?.gameType?.length
    ) {
      prevGamesData[ind].gameType = initialLoadGames[ind].gameType.slice(
        prevGamesDisplayCount,
        getFilteredCount
      );
      prevGamesData[ind].filteredCount = getFilteredCount;
      initialLoadGames[ind].filteredCount = getFilteredCount;
    }
    for (let n = 0; n < prevGamesData.length; n++) {
      prevGamesData[n]['gameSpecificNextClick'] = true;
      prevGamesData[n]['gameSpecificRef'] = '';
    }
    prevGamesData[ind]['gameSpecificRef'] = prevGamesData[ind]?.gameref;
  }
  if (prevGamesDisplayCount >= 0) {
    dispatch(storeInitialLoadGames(prevGamesData));
    dispatch(filteredGamesData(initialLoadGames));
    dispatch(initialLoadGamesCopy(prevGamesData));
  }
};

/**
 * Method to modify games count
 * @param { array } prevGamesData
 * @param { number } prevGamesDisplayCount
 */
function getGamesCount(prevGamesData, prevGamesDisplayCount) {
  if (
    prevGamesDisplayCount >= 3 &&
    Array.isArray(prevGamesData.gametohighlight) &&
    prevGamesData.gametohighlight.length > 0 &&
    Number(prevGamesData.gamesdisplaycountininitialload) === 9
  ) {
    prevGamesDisplayCount = prevGamesDisplayCount - 3;
  }
  return prevGamesDisplayCount;
}

/**
 * Method to get favourite games
 */
export const getFavouriteGames = () => dispatch => {
  const favGamesArray = {};
  const getAllAtributes = document.querySelectorAll(
    '[data-app="CasinoFavouriteApp"]'
  );
  const sessionToken = getCookie('AppSession');
  if (getAllAtributes?.length > 0 && sessionToken) {
    const params = getAllAtributes[0]?.dataset;
    const baseUrl = `/fsb-api-rest/gameTypeFavorites.fsb`;
    const urlParams = '?access_token=' + sessionToken;
    let gameParams = '';
    if (Number(params?.limit) > 0 && Number(params?.offset) >= 0) {
      gameParams = '&offset=' + params?.offset + '&limit=' + params?.limit;
    }
    let url;
    if (gameParams) {
      url = baseUrl + urlParams + gameParams;
    } else {
      url = baseUrl + urlParams;
    }
    return getRequest(url).then(getFavouriteGames => {
      dispatch(storeFilteredFavGames([]));
      dispatch(setFavouriteGames([]));
      if (Array.isArray(getFavouriteGames?.gameTypeFavorite)) {
        for (const getFavGame of getFavouriteGames?.gameTypeFavorite) {
          if (Array.isArray(favGamesArray['gameType'])) {
            favGamesArray.gameType.push(getFavGame?.gameType);
          } else {
            favGamesArray['gameType'] = [];
            favGamesArray.gameType.push(getFavGame?.gameType);
          }
        }
        for (const game of favGamesArray?.gameType) {
          if (Array.isArray(game.metadata) && game.metadata.length > 0) {
            for (let g = 0; g < game.metadata.length; g++) {
              if (game.metadata[g].name === 'imageThumbnail') {
                game.imageThumbnail = game.metadata[g].value;
              }
              if (game.metadata[g].name === 'backgroundImage') {
                game['baseImageUrl'] = game.metadata[g].value;
              }
            }
          }
          const getImageUrl = buildImageUrl(game);
          game['displayTags'] =
            typeof game?.tag === 'string' ? game?.tag.split(',') : game?.tag;
          game['imageUrl'] = game.imageThumbnail
            ? game.imageThumbnail
            : getImageUrl?.imageUrl;
          game['placeHolderImageUrl'] = getImageUrl?.placeHolderImageUrl;
        }
        favGamesArray['title'] = params?.title;
        favGamesArray['icon'] = params?.icon;
        favGamesArray['hidedemoplay'] = params?.hidedemoplay;
        favGamesArray['gamesdisplaycountininitialload'] =
          params?.gamesdisplaycountininitialload;
        favGamesArray['buttontext'] = params?.buttontext;
        const storeAllGames = { ...favGamesArray };
        if (params?.gamesdisplaycountininitialload) {
          storeAllGames['gamesCount'] = storeAllGames?.gameType?.length;
          const gameCount = Number(params?.gamesdisplaycountininitialload);
          storeAllGames['gameType'] = storeAllGames?.gameType?.slice(
            0,
            gameCount
          );
          storeAllGames['filteredCount'] = gameCount;
          favGamesArray['filteredCount'] = gameCount;
        }
        dispatch(storeFilteredFavGames(storeAllGames));
        dispatch(setDisplayTagsOnFavourite(favGamesArray));
      }
    });
  }
};

/**
 * Method to get next fav set of games
 */
export const gotoNextFavSelectionGames = (
  allGamesData,
  gamesData
) => dispatch => {
  const favGamesArray = allGamesData;
  const storeAllGames = { ...gamesData };
  let nextGamesDisplayCount;
  if (storeAllGames.gamesdisplaycountininitialload) {
    const displayCount = Number(storeAllGames.gamesdisplaycountininitialload);
    const getFilteredCount = Number(storeAllGames.filteredCount);
    if (
      getFilteredCount !== favGamesArray?.gameType?.length &&
      favGamesArray?.gameType?.length > displayCount
    ) {
      nextGamesDisplayCount = Number(displayCount) + Number(getFilteredCount);
    }
    if (nextGamesDisplayCount <= favGamesArray?.gameType?.length) {
      storeAllGames.gameType = favGamesArray.gameType.slice(
        getFilteredCount,
        nextGamesDisplayCount
      );
      storeAllGames.filteredCount = nextGamesDisplayCount;
      favGamesArray.filteredCount = nextGamesDisplayCount;
    } else {
      if (nextGamesDisplayCount > 0) {
        const lastPageGamesCount =
          Number(nextGamesDisplayCount) -
          Number(favGamesArray?.gameType?.length);
        if (lastPageGamesCount > 0) {
          nextGamesDisplayCount =
            Number(nextGamesDisplayCount) - Number(lastPageGamesCount);
          storeAllGames.gameType = favGamesArray.gameType.slice(
            getFilteredCount,
            nextGamesDisplayCount
          );
          storeAllGames.filteredCount = nextGamesDisplayCount;
          favGamesArray.filteredCount = nextGamesDisplayCount;
          storeAllGames['lastPageCount'] = storeAllGames?.gameType?.length;
          favGamesArray['lastPageCount'] = storeAllGames?.gameType?.length;
        }
      }
    }
  }
  if (nextGamesDisplayCount > 0) {
    dispatch(storeFilteredFavGames(storeAllGames));
    dispatch(setDisplayTagsOnFavourite(favGamesArray));
  }
};

/**
 * Method to get prev fav set of games
 */
export const gotoPrevFavSelectionGames = (
  allGamesData,
  gamesData
) => dispatch => {
  const favGamesArray = allGamesData;
  const storeAllGames = { ...gamesData };
  let prevGamesDisplayCount;
  if (storeAllGames.gamesdisplaycountininitialload) {
    const displayCount = Number(storeAllGames.gamesdisplaycountininitialload);
    let getFilteredCount = Number(storeAllGames.filteredCount);
    if (storeAllGames?.lastPageCount) {
      prevGamesDisplayCount =
        Number(getFilteredCount) - Number(storeAllGames?.lastPageCount);
      getFilteredCount =
        Number(getFilteredCount) - Number(storeAllGames?.lastPageCount);
      prevGamesDisplayCount = prevGamesDisplayCount - Number(displayCount);
      storeAllGames.lastPageCount = '';
      favGamesArray.lastPageCount = '';
    } else {
      prevGamesDisplayCount = Number(getFilteredCount) - Number(displayCount);
      getFilteredCount = Number(getFilteredCount) - Number(displayCount);
      prevGamesDisplayCount = prevGamesDisplayCount - Number(displayCount);
    }
    if (
      prevGamesDisplayCount >= 0 &&
      prevGamesDisplayCount <= favGamesArray?.gameType?.length
    ) {
      storeAllGames.gameType = favGamesArray.gameType.slice(
        prevGamesDisplayCount,
        getFilteredCount
      );
      storeAllGames.filteredCount = getFilteredCount;
      favGamesArray.filteredCount = getFilteredCount;
    }
  }
  if (prevGamesDisplayCount >= 0) {
    dispatch(storeFilteredFavGames(storeAllGames));
    dispatch(setDisplayTagsOnFavourite(favGamesArray));
  }
};

/**
 * Method to set tags on favourite games
 */
export const setDisplayTagsOnFavourite = favGamesArray => (
  dispatch,
  getState
) => {
  const { gamesData } = getState()?.CasinoGamesData;
  gamesData?.forEach(item => {
    item?.gameType?.forEach(allGamesItem => {
      favGamesArray?.gameType?.forEach(favoriteGamesItem => {
        if (
          allGamesItem?.name === favoriteGamesItem?.name ||
          allGamesItem?.remoteRef === favoriteGamesItem?.remoteRef
        ) {
          favoriteGamesItem.displayTags = stringToArray(allGamesItem?.tag);
          favoriteGamesItem.tagColours = stringToArray(
            allGamesItem?.tagColours
          );
          favoriteGamesItem.featuredTag = allGamesItem?.featuredTag;
        }
      });
    });
  });
  dispatch(setFavouriteGames(favGamesArray));
};

const composeEnhancers = getComposeEnhancers({ name: 'all-games-store' });
export default initialState => {
  const state = JSON.parse(JSON.stringify(initialState));
  Object.keys(initialStateTemplate.CasinoGamesData).forEach(key => {
    if (state?.CasinoGamesData && state?.CasinoGamesData[key]) {
      initialStateTemplate.CasinoGamesData[key] = state.CasinoGamesData[key];
    }
  });
  return createStore(
    reducer,
    initialStateTemplate,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
};
export const addGametoFavourite = id => dispatch => {
  const sessionToken = getCookie('AppSession');
  if (sessionToken) {
    const baseUrl = `/fsb-api-rest/addGameTypeFavorite.fsb`;
    const params = '?access_token=' + sessionToken + '&gameTypeId=' + id;
    const url = baseUrl + params;
    return postRequest(url).then(addGame => {
      if (addGame?.gameTypeFavorite && addGame?.gameTypeFavorite[0]?.gameType) {
        dispatch(getFavouriteGames());
        setTimeout(() => {
          dispatch(fetchData(null, null, null));
        }, 1000);
      }
    });
  } else {
    PubSub.emit(PubsubEvents.callLoginModalFromCasino, {
      modal: true,
      page: 'fromCasino',
    });
  }
};
/**
 * Method to remove game from favourite
 */
export const removeGameFromFavourite = id => dispatch => {
  const sessionToken = getCookie('AppSession');
  if (sessionToken) {
    const baseUrl = `/fsb-api-rest/removeGameTypeFavorite.fsb`;
    const params = '?access_token=' + sessionToken + '&gameTypeId=' + id;
    const url = baseUrl + params;
    return deleteRequest(url).then(() => {
      dispatch(getFavouriteGames());
      setTimeout(() => {
        dispatch(fetchData(null, null, null));
      }, 1000);
    });
  }
};
/**
 * Method to filter only unique values
 */
const onlyUnique = (value, index, self) => {
  return value && self.indexOf(value) === index;
};
